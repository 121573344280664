<script setup>
import SectionHeader from "../texts/SectionHeader.vue";
import BillingDetails from "../billing/BillingDetails.vue";
import { formatCurrency } from "../../services/utils/formatter";
import { quoteSubtotal } from "../../services/utils/calc";

const props = defineProps({
    quote: { type: Object, required: true },
});
</script>

<template>
    <div class="align-center">
        <SectionHeader label="Billing Details">
            <template v-slot:action>
                <span class="font-bold">{{
                    formatCurrency(quoteSubtotal(props.quote))
                }}</span>
            </template>
        </SectionHeader>
        <BillingDetails :quote="props.quote" />
    </div>
</template>
