import { NETWORK } from "../../const/quote";
import { layerSubtotal } from "./calc";

export const getAllTerritoriesIds = (quote) => {
    return quote.territories.map((territory) => territory.id);
};

export const getTargetedTerritoriesIds = (quote) => {
    if (!quote.targetingFilters || quote.targetingFilters.length === 0) {
        return [];
    }

    const allTerritories = getAllTerritoriesIds(quote);

    const targetedIds = quote.targetingFilters.filter((filter) =>
        allTerritories.includes(filter.territoryId)
    );

    return quote.territories.filter((t) => !targetedIds.includes(t.id));
};

export const territoriesSubtotal = (territories) => {
    const subtotalDefault = {
        [NETWORK.URBAN]: 0,
        [NETWORK.POSTIES]: 0,
        [NETWORK.RURAL]: 0,
        [NETWORK.POBOXES]: 0,
        [NETWORK.COUNTERS]: 0,
    };

    return territories
        .map((territory) =>
            territory.networkSelections.reduce(
                (acc, layer) => {
                    acc[layer] = layerSubtotal(territory, layer);
                    return acc;
                },
                { ...subtotalDefault }
            )
        )
        .reduce(
            (acc, territorySubtotal) => {
                Object.keys(territorySubtotal).forEach(
                    (layer) => (acc[layer] += territorySubtotal[layer])
                );
                return acc;
            },
            { ...subtotalDefault }
        );
};

export const territoriesTotal = (subtotalMatrix) => {
    return Object.keys(subtotalMatrix).reduce(
        (acc, layer) => acc + subtotalMatrix[layer],
        0
    );
};

export const sliceVolume = (quote, network, slice) => {
    return quote.territories
        .filter(
            (territory) =>
                territory.networkSelections.includes(network) &&
                territory.volumeSelections[network].includes(slice)
        )
        .reduce(
            (acc, territory) => territory.volumesMatrix[network][slice] + acc,
            0
        );
};
