<script setup>
import { ref } from "vue";

const props = defineProps({
    id: { type: String, required: true },
});

const isExpanded = ref(false);

const toggleAccordion = () => {
    isExpanded.value = !isExpanded.value;
};
</script>

<template>
    <div class="border rounded p-4">
        <div class="flex justify-between">
            <slot name="title"></slot>
            <div class="flex items-start mt-8">
                <slot v-if="!isExpanded" name="actions"></slot>
                <button
                    @click="toggleAccordion()"
                    class="flex items-center ml-4"
                    :aria-expanded="isExpanded"
                    :aria-controls="`collapse${props.id}`"
                >
                    <svg
                        class="w-3 transition-all duration-200 transform"
                        :class="{
                            'rotate-180': isExpanded,
                            'rotate-0': !isExpanded,
                        }"
                        fill="none"
                        stroke="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 10"
                        aria-hidden="true"
                    >
                        <path
                            d="M15 1.2l-7 7-7-7"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>

        <div v-show="isExpanded" :id="`collapse${props.id}`">
            <slot name="content"></slot>
        </div>
    </div>
</template>
