import { ENDPOINT, HTTP } from "../../const/http";
import { NETWORK, VOLUME_SLICES } from "../../const/quote";
import { useCampaignStore } from "../../stores/campaign";
import { useQuoteStore } from "../../stores/quote";
import { reportError } from "../utils/telemetry";
import { apiDate } from "../utils/date";
import { sliceVolume } from "../utils/territory";
import { showToast, toastStatus } from "../utils/toast";
import { constructOptions, useApi } from "./common";

export const getFarmerPremium = async (quote) => {
    const dairyFarmersVolume = sliceVolume(
        quote,
        NETWORK.RURAL,
        VOLUME_SLICES.DAIRY
    );

    if (dairyFarmersVolume === 0) return;

    const { data, fetchApi } = useApi({
        optionsHandler: constructOptions,
    });
    const store = useQuoteStore();
    const campaignStore = useCampaignStore();

    try {
        const customerCmsCode = quote.quoteMeta.customerCmsCode;
        const campaignStartDate =
            campaignStore.campaigns[quote.id]?.distributionDate;
        const campaignStartDateFormatted = apiDate(campaignStartDate);

        const params = {
            qty: dairyFarmersVolume,
            ...(customerCmsCode && { cms_acct_code: customerCmsCode }),
            ...(campaignStartDate && {
                start_date: campaignStartDateFormatted,
            }),
        };

        await fetchApi({
            endPoint: ENDPOINT.GET_FARMER_QUOTE(new URLSearchParams(params)),
            method: HTTP.GET,
        });

        let rates = { rate: 0, amount: 0 };
        if (data.value?.farmer_premium?.rate) {
            rates = {
                rate: data.value.farmer_premium.rate,
                amount: data.value.farmer_premium.amount,
            };
        }

        store.setQuoteDetailsValue(
            quote.id,
            "dairyFarmerPremium",
            rates.amount
        );
    } catch (error) {
        store.setError(error);
        reportError(error);
        showToast("Failed to load the farmer premium fee.", toastStatus.ERROR);
    }
};
