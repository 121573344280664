import { ENDPOINT, HTTP } from "../../const/http";
import { DEFAULT_ADMIN_FEE } from "../../const/quote";
import { useCampaignStore } from "../../stores/campaign";
import { useQuoteStore } from "../../stores/quote";
import { reportError } from "../utils/telemetry";
import { getWeightBracket } from "../utils/calc";
import { apiDate } from "../utils/date";
import { numberFormat } from "../utils/formatter";
import { showToast, toastStatus } from "../utils/toast";
import { constructOptions, useApi } from "./common";

export const getAdminFee = async (quote) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructOptions,
        toastMgs: false,
    });
    const store = useQuoteStore();
    const campaignStore = useCampaignStore();

    try {
        const customerCmsCode = quote.quoteMeta.customerCmsCode;
        const campaignStartDate =
            campaignStore.campaigns[quote.id]?.distributionDate;
        const campaignStartDateFormatted = apiDate(campaignStartDate);

        const { customWeightBracket, weight } = quote.quoteDetails;
        const { pageCount } = quote.printingSpecs;

        // respect custom weight bracket choice to override weight/bracket calculations
        const weightBracket =
            customWeightBracket ?? getWeightBracket(weight * pageCount);

        const params = {
            ...(customerCmsCode && { cms_acct_code: customerCmsCode }),
            ...(weightBracket && { weight_range: weightBracket }),
            ...(campaignStartDate && {
                start_date: campaignStartDateFormatted,
            }),
        };

        await fetchApi({
            endPoint: ENDPOINT.GET_ADMIN_FEE(new URLSearchParams(params)),
            method: HTTP.GET,
        });

        let adminFee = DEFAULT_ADMIN_FEE;

        if (data.value?.rate) {
            adminFee = parseFloat(data.value.rate);
        }

        store.setQuoteDetailsValue(quote.id, "adminFee", adminFee);
    } catch (error) {
        store.setError(error);
        reportError(error);
        showToast(
            `Failed to load the administration fee. Using value of <span class="text-peppermint font-bold">$${numberFormat(
                DEFAULT_ADMIN_FEE
            )}</span>.`,
            toastStatus.ERROR
        );
    }
};
