<script setup>
const props = defineProps({
    onClose: { type: Function, required: true },
    title: { type: String },
});
</script>

<template>
    <div
        tabindex="-1"
        class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen"
    >
        <div class="relative w-full max-w-7xl h-screen">
            <!-- Modal content -->
            <div
                class="relative bg-white rounded-lg shadow dark:bg-gray-700 h-screen"
            >
                <!-- Modal header -->
                <div
                    class="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600"
                >
                    <h3
                        v-if="props.title"
                        class="text-xl font-semibold text-gray-900 dark:text-white"
                    >
                        {{ props.title }}
                    </h3>
                    <button
                        type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        @click="props.onClose()"
                    >
                        <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
