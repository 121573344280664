<script setup>
import { onMounted, onUnmounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import UtilityButton from "../components/buttons/UtilityButton.vue";
import ErrorCard from "../components/cards/ErrorCard.vue";
import CampaignIcon from "../components/icons/CampaignIcon.vue";
import DeleteIcon from "../components/icons/DeleteIcon.vue";
import MagnifierIcon from "../components/icons/MagnifierIcon.vue";
import PencilIcon from "../components/icons/PencilIcon.vue";
import ToggleCheckbox from "../components/input/ToggleCheckbox.vue";
import CampaignSummary from "../components/sections/CampaignSummary.vue";
import CoordinatorSummary from "../components/sections/CoordinatorSummary.vue";
import CostSummary from "../components/sections/CostSummary.vue";
import CustomerSummary from "../components/sections/CustomerSummary.vue";
import JobSummary from "../components/sections/JobSummary.vue";
import LodgementSummary from "../components/sections/LodgementSummary.vue";
import PricingSummary from "../components/sections/PricingSummary.vue";
import PrintingSummary from "../components/sections/PrintingSummary.vue";
import QuoteConfirmation from "../components/sections/QuoteConfirmation.vue";
import TerritoriesSummary from "../components/sections/TerritoriesSummary.vue";
import SectionHeader from "../components/texts/SectionHeader.vue";
import AccordionContainer from "../components/utils/AccordionContainer.vue";
import LoadingBackdrop from "../components/utils/LoadingBackdrop.vue";
import { QUOTE_LIMIT } from "../const/quote";
import { BASE_PATH, QUOTES_PATH } from "../const/route";
import { useDb } from "../services/firebase";
import { quoteSubtotal } from "../services/utils/calc";
import {
    getCampaignId,
    hasCampaign,
    isCampaignCreated,
    isCampaignLocked,
} from "../services/utils/campaign";
import { formatCurrency } from "../services/utils/formatter";
import {
    redirectToCXView,
    redirectToClientView,
    redirectToLibertyCampaign,
    redirectToLibertyJob,
} from "../services/utils/navigation";
import {
    hasDistributionData,
    hasJobNumber,
    isReachhomeQuote,
} from "../services/utils/quote";
import { useCampaignStore } from "../stores/campaign";
import { useQuoteStore } from "../stores/quote";
import BaseView from "./BaseView.vue";
import ErrorView from "./ErrorView.vue";
import SearchView from "./SearchView.vue";

const router = useRouter();
const route = useRoute();
const db = useDb();

const campaignStore = useCampaignStore();
const quoteStore = useQuoteStore();
const state = reactive({
    error: undefined,
    isLoading: true,
    quotes: null,
});
let unsub;

const clearListener = () => {
    if (unsub) {
        unsub();
    }
};

onMounted(async () => {
    quoteStore.$subscribe((mut, storeState) => {
        state.error = storeState.error;
        state.isLoading = storeState.loadingQuote;
        state.quotes = storeState.quotes;
    });

    quoteStore.setLoading(true);

    clearListener();

    let quoteIds = [];
    if (route.query.ids) {
        quoteIds = route.query.ids.split(",");
    }

    if (quoteIds.length === 0) {
        router.push(BASE_PATH);
    } else if (quoteIds.length > QUOTE_LIMIT) {
        state.error = `You can have at most ${QUOTE_LIMIT} quotes.`;
        quoteStore.setLoading(false);
    } else {
        unsub = db.getQuotes(quoteIds, quoteStore);
    }
});

onUnmounted(() => {
    clearListener();
    quoteStore.$dispose();
});

const reloadQuery = (ids) => {
    router
        .push({
            path: `${BASE_PATH}${QUOTES_PATH}`,
            query: {
                ids,
            },
        })
        .then(() => router.go());
};

const removeQuote = (quote) => {
    const currentQuery = [...route.query.ids.split(",")];
    const indexToRemove = currentQuery.indexOf(quote.id);

    if (!route.query.ids || indexToRemove < 0) return;
    if (
        confirm(
            `Are you sure you want to remove quote [ ${quote.quoteMeta.name} ] ?`
        )
    ) {
        currentQuery.splice(indexToRemove, 1);
        reloadQuery(currentQuery.join(","));
    }
};
</script>

<template>
    <LoadingBackdrop v-if="state.isLoading" />
    <ErrorView v-else-if="state.error" :message="state.error"> </ErrorView>
    <BaseView v-else>
        <div class="flex lg:flex-row flex-col">
            <div class="lg:w-8/12 w-full sm:px-8 px-6 lg:py-14 md:py-8 py-4">
                <div class="flex">
                    <h1 class="heading-text font-bold text-3xl mt-4">
                        Quote Summary
                    </h1>
                    <SearchView
                        v-if="
                            (quoteStore.hasMultipleQuotes &&
                                !isCampaignLocked(
                                    campaignStore.campaignStatus
                                )) ||
                            (quoteStore.hasSingleQuote &&
                                !hasJobNumber(quoteStore.quotes[0]))
                        "
                    />
                </div>

                <div
                    v-if="
                        quoteStore.hasMultipleQuotes &&
                        isCampaignLocked(campaignStore.campaignStatus)
                    "
                    class="uppercase text-center bg-green-100 text-green-800 font-medium my-2 py-1 dark:bg-green-900 dark:text-green-300"
                >
                    Campaign confirmed
                </div>
                <div
                    v-else-if="
                        quoteStore.hasMultipleQuotes &&
                        isCampaignCreated(quoteStore.quotes)
                    "
                    class="uppercase text-center bg-green-100 text-green-800 font-medium my-2 py-1 dark:bg-green-900 dark:text-green-300"
                >
                    Campaign created
                </div>
                <div
                    v-else-if="
                        quoteStore.hasSingleQuote &&
                        hasJobNumber(quoteStore.quotes[0])
                    "
                    class="uppercase text-center bg-green-100 text-green-800 font-medium my-2 py-1 dark:bg-green-900 dark:text-green-300"
                >
                    Job created
                </div>
                <p
                    v-if="
                        quoteStore.hasMultipleQuotes &&
                        isCampaignCreated(quoteStore.quotes) &&
                        getCampaignId(quoteStore.quotes)
                    "
                    class="text-center text-lg"
                >
                    Campaign ID
                    <a
                        class="cursor-pointer text-lg"
                        @click="
                            () =>
                                redirectToLibertyCampaign(
                                    getCampaignId(quoteStore.quotes)
                                )
                        "
                        >{{ getCampaignId(quoteStore.quotes) }}</a
                    >
                </p>
                <p
                    v-if="
                        quoteStore.hasSingleQuote &&
                        hasJobNumber(quoteStore.quotes[0])
                    "
                    class="text-center text-lg"
                >
                    Job No:
                    <a
                        class="cursor-pointer text-lg"
                        @click="
                            () =>
                                redirectToLibertyJob(
                                    quoteStore.quotes[0].quoteMeta.jobNumber
                                )
                        "
                        >{{
                            quoteStore.quotes[0].quoteMeta.jobContractNumber
                        }}</a
                    >
                </p>
                <p
                    v-if="
                        quoteStore.hasSingleQuote &&
                        hasJobNumber(quoteStore.quotes[0])
                    "
                    class="text-center text-lg"
                >
                    Job Inquiry ID:
                    <a
                        class="cursor-pointer text-lg"
                        @click="
                            () =>
                                redirectToLibertyJob(
                                    quoteStore.quotes[0].quoteMeta.jobNumber
                                )
                        "
                        >{{ quoteStore.quotes[0].quoteMeta.jobNumber }}</a
                    >
                </p>
                <AccordionContainer
                    v-for="quote in quoteStore.quotes"
                    :key="quote.id"
                    :id="quote.id"
                    class="mt-4"
                >
                    <template v-slot:title>
                        <div class="flex flex-col w-full">
                            <span
                                class="bg-bluesteel-tint-5 dark:text-dark-grey mb-1 w-20 text-xs uppercase px-1.5 py-0.5 rounded"
                                >Letterbox</span
                            >
                            <SectionHeader
                                :label="`${quote.quoteMeta.name}`"
                                :show-divider="false"
                            />
                            <JobSummary :quote="quote" />
                        </div>
                    </template>
                    <template v-slot:actions
                        ><span class="text-lg font-bold ml-4">{{
                            formatCurrency(quoteSubtotal(quote))
                        }}</span></template
                    >
                    <template v-slot:content>
                        <ErrorCard v-if="!hasDistributionData(quote)">
                            <p>
                                The quote does not have all required data,
                                please open the quote in the
                                <a
                                    class="cursor-pointer"
                                    @click="
                                        () =>
                                            redirectToCXView(quote.quoteMeta.id)
                                    "
                                    >application</a
                                >
                                and re-save the quote.
                            </p>
                        </ErrorCard>
                        <div class="flex justify-between mt-2">
                            <ToggleCheckbox
                                label="REACHHOME quote"
                                :checked="isReachhomeQuote(quote)"
                                :disabled="true"
                                class="my-4"
                            />
                            <div class="self-center">
                                <UtilityButton
                                    v-if="
                                        quoteStore.hasMultipleQuotes &&
                                        hasCampaign(quote)
                                    "
                                    label=""
                                    tooltip="View campaign in Liberty"
                                    inverse
                                    noBorder
                                    :onClick="
                                        () =>
                                            redirectToLibertyCampaign(
                                                quote.quoteMeta.campaignId
                                            )
                                    "
                                >
                                    <CampaignIcon class="h-6 w-6" />
                                </UtilityButton>
                                <UtilityButton
                                    v-else-if="
                                        quoteStore.hasSingleQuote &&
                                        hasJobNumber(quote)
                                    "
                                    label=""
                                    tooltip="View job in Liberty"
                                    inverse
                                    noBorder
                                    :onClick="
                                        () =>
                                            redirectToLibertyJob(
                                                quote.quoteMeta.jobNumber
                                            )
                                    "
                                >
                                    <CampaignIcon class="h-6 w-6" />
                                </UtilityButton>
                                <UtilityButton
                                    label=""
                                    tooltip="View in Editor"
                                    inverse
                                    noBorder
                                    class="p-0"
                                    :onClick="
                                        () =>
                                            redirectToCXView(quote.quoteMeta.id)
                                    "
                                >
                                    <PencilIcon class="h-6 w-6" />
                                </UtilityButton>
                                <UtilityButton
                                    label=""
                                    tooltip="View in client mode"
                                    inverse
                                    noBorder
                                    :onClick="
                                        () =>
                                            redirectToClientView(
                                                quote.quoteMeta.id
                                            )
                                    "
                                >
                                    <MagnifierIcon class="h-6 w-6" />
                                </UtilityButton>
                                <UtilityButton
                                    label=""
                                    tooltip="Remove quote"
                                    inverse
                                    noBorder
                                    danger
                                    :onClick="() => removeQuote(quote)"
                                >
                                    <DeleteIcon class="h-6 w-6" />
                                </UtilityButton>
                            </div>
                        </div>
                        <CostSummary :quote="quote" class="mb-4" />
                        <TerritoriesSummary :quote="quote" class="mb-4" />
                        <LodgementSummary :quote="quote" />
                        <PrintingSummary :quote="quote" />
                    </template>
                </AccordionContainer>
            </div>
            <div
                class="lg:w-4/12 w-full bg-bluesteel-tint-5 dark:bg-gray-900 flex flex-col lg:mt-4 lg:px-8 md:px-7 px-4 lg:py-20 md:py-10 py-6"
            >
                <CustomerSummary class="mb-4" />
                <PricingSummary class="mb-4" />
                <CoordinatorSummary class="mb-4" />
                <CampaignSummary
                    v-if="quoteStore.hasMultipleQuotes"
                    :quote="quoteStore.quotes[0]"
                />
                <QuoteConfirmation />
            </div>
        </div>
    </BaseView>
</template>
