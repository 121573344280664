<script setup>
import { reactive } from "vue";
import {
    CAMPAIGN_NAME_CHAR_LIMIT,
    JOB_NAME_CHAR_LIMIT,
} from "../../const/quote";
import {
    createCampaign,
    createStandaloneJob,
    getCampaign,
    updateCampaign,
} from "../../services/apis/campaign";
import { getCampaignId, isCampaignLocked } from "../../services/utils/campaign";
import { isDistributionDateValid } from "../../services/utils/date";
import { hasCustomerDetails, hasJobNumber } from "../../services/utils/quote";
import { reportError } from "../../services/utils/telemetry";
import { showToast, toastStatus } from "../../services/utils/toast";
import { useCampaignStore } from "../../stores/campaign";
import { useCustomerStore } from "../../stores/customer";
import { useQuoteStore } from "../../stores/quote";
import CreateCampaignButton from "../buttons/CreateCampaignButton.vue";
import ProgressSpin from "../utils/ProgressSpin.vue";
import { useJobStore } from "../../stores/job";

const state = reactive({
    loading: false,
});

const campaignStore = useCampaignStore();
const customerStore = useCustomerStore();
const jobStore = useJobStore();
const quoteStore = useQuoteStore();

const validateCampaign = () => {
    if (isCampaignLocked(campaignStore.campaignStatus)) {
        showToast(
            "The campaign no longer accepts modifications.",
            toastStatus.ERROR
        );
        return false;
    }

    for (const campaign of Object.values(campaignStore.campaigns)) {
        if (!isDistributionDateValid(campaign.distributionDate)) {
            showToast(
                "Distribution date for all quotes must be at least one week in advance.",
                toastStatus.ERROR
            );
            return false;
        }
        if (campaign.jobName.trim() === "") {
            showToast(
                "The job name for all quotes cannot be empty.",
                toastStatus.ERROR
            );
            return false;
        }
        if (campaign.jobName.trim().length > JOB_NAME_CHAR_LIMIT) {
            showToast(
                `The job name must not exceed ${JOB_NAME_CHAR_LIMIT} characters.`,
                toastStatus.ERROR
            );
            return false;
        }
    }

    if (!quoteStore.quotes[0].quoteMeta.customerCmsCode) {
        showToast(
            "The quote does not have an associated customer.",
            toastStatus.ERROR
        );
        return false;
    }
    if (!hasCustomerDetails(customerStore.customerDetails)) {
        showToast(
            "The customer associated with the quote cannot be found by its CMS number.",
            toastStatus.ERROR
        );
        return false;
    }
    if (campaignStore.campaignName.trim() === "") {
        showToast("The campaign name cannot be empty.", toastStatus.ERROR);
        return false;
    }
    if (campaignStore.campaignName.trim().length > CAMPAIGN_NAME_CHAR_LIMIT) {
        showToast(
            `The campaign name must not exceed ${CAMPAIGN_NAME_CHAR_LIMIT} characters.`,
            toastStatus.ERROR
        );
        return false;
    }

    return true;
};

const validateJob = () => {
    if (!quoteStore.hasSingleQuote) return false;

    if (hasJobNumber(quoteStore.quotes[0])) {
        showToast(
            "The job no longer accepts modifications.",
            toastStatus.ERROR
        );
        return false;
    }

    if (!isDistributionDateValid(jobStore.distributionDate)) {
        showToast(
            "Distribution date must be at least one week in advance.",
            toastStatus.ERROR
        );
        return false;
    }
    if (jobStore.jobName.trim() === "") {
        showToast("The job name cannot be empty.", toastStatus.ERROR);
        return false;
    }
    if (jobStore.jobName.trim().length > JOB_NAME_CHAR_LIMIT) {
        showToast(
            `The job name must not exceed ${JOB_NAME_CHAR_LIMIT} characters.`,
            toastStatus.ERROR
        );
        return false;
    }

    if (!quoteStore.quotes[0].quoteMeta.customerCmsCode) {
        showToast(
            "The quote does not have an associated customer.",
            toastStatus.ERROR
        );
        return false;
    }
    if (!hasCustomerDetails(customerStore.customerDetails)) {
        showToast(
            "The customer associated with the quote cannot be found by its CMS number.",
            toastStatus.ERROR
        );
        return false;
    }

    return true;
};

const onConfirmCampaign = async () => {
    const campaignId = getCampaignId(quoteStore.quotes);
    if (campaignId) {
        await getCampaign(campaignId);
    }

    if (!validateCampaign()) return;

    if (campaignId) {
        await updateCampaign(
            campaignId,
            quoteStore.quotes,
            campaignStore.quotes,
            campaignStore.campaigns,
            campaignStore.campaignName
        );
    } else {
        await createCampaign(
            quoteStore.quotes,
            campaignStore.campaigns,
            campaignStore.campaignName
        );
    }
};

const onConfirmJob = async () => {
    if (!validateJob()) return;

    await createStandaloneJob(
        quoteStore.quotes[0],
        jobStore.distributionDate,
        jobStore.jobName
    );
};

const onConfirm = async () => {
    state.loading = true;

    try {
        if (
            quoteStore.hasMultipleQuotes &&
            !isCampaignLocked(campaignStore.campaignStatus)
        ) {
            await onConfirmCampaign();
        }
        if (quoteStore.hasSingleQuote && !hasJobNumber(quoteStore.quotes[0])) {
            await onConfirmJob();
        }
    } catch (error) {
        showToast(
            `An unexpected error has occurred. Please try again.\nError: ${error}`,
            toastStatus.ERROR
        );
        reportError(error);
    } finally {
        state.loading = false;
    }
};
</script>

<template>
    <div
        v-if="
            (quoteStore.hasMultipleQuotes &&
                !isCampaignLocked(campaignStore.campaignStatus)) ||
            (quoteStore.hasSingleQuote && !hasJobNumber(quoteStore.quotes[0]))
        "
        class="self-center text-center mt-4"
    >
        <ProgressSpin v-if="state.loading" />
        <CreateCampaignButton v-else :on-confirm="onConfirm" />
    </div>
</template>
