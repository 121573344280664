<script setup>
import TerritoriesSubtotal from "../territories/TerritoriesSubtotal.vue";
import TerritoryLegend from "../territories/TerritoryLegend.vue";
import TerritorySummaryCard from "../territories/TerritorySummaryCard.vue";
import SectionHeader from "../texts/SectionHeader.vue";
import SectionSubtitle from "../texts/SectionSubtitle.vue";

const props = defineProps({
    quote: { type: Object, required: true },
});
</script>

<template>
    <div class="w-full overflow-x-auto">
        <SectionHeader label="Territories Summary" class="mb-4" />
        <TerritoryLegend class="mb-4" />
        <TerritoriesSubtotal :quote="props.quote" class="my-4" />
        <SectionSubtitle label="Territory Breakdown" />
        <div class="grid gap-4 mt-2">
            <TerritorySummaryCard
                v-for="(territory, index) in props.quote.territories"
                :key="territory.id"
                :quote="props.quote"
                :territory="territory"
                :index="index"
            />
        </div>
    </div>
</template>
