import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from "../../const/config";

export const formatCurrency = (val) => {
    return val
        .toLocaleString(DEFAULT_LOCALE, {
            style: "currency",
            currency: DEFAULT_CURRENCY,
        })
        .replace("$", "$\xa0"); // replace with non-breaking space
};

export const toTitleCase = (str) => {
    if (!str) return "";
    return str.replace(
        /^[a-z]|[A-Z]/g,
        (c, i) => (i ? " " : "") + c.toUpperCase()
    );
};

export const formatPostcode = (postcode) => {
    return `${postcode.toString().length < 4 ? "0" : ""}${postcode}`;
};

export const numberFormat = (value, options) => {
    return new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
    }).format(value);
};

export const financialNumberFormat = (value) => {
    return Number.parseFloat(value).toFixed(2);
};
