import { defineStore } from "pinia";
import { ref } from "vue";
import { getStartOfDay } from "../services/utils/date";
import { CAMPAIGN_NAME_CHAR_LIMIT, CAMPAIGN_STATUS } from "../const/quote";

export const useCampaignStore = defineStore("campaign", () => {
    const campaigns = ref({});
    const campaignName = ref("");
    const campaignStatus = ref(CAMPAIGN_STATUS.PENDING);
    const quotes = ref([]);

    function updateCampaignName(newName) {
        campaignName.value = newName.substring(0, CAMPAIGN_NAME_CHAR_LIMIT);
    }

    function updateCampaignStatus(newStatus) {
        if (!CAMPAIGN_STATUS[newStatus]) {
            campaignStatus.value = CAMPAIGN_STATUS.OTHER;
        }
        campaignStatus.value = CAMPAIGN_STATUS[newStatus];
    }

    function updateQuotes(newQuotes) {
        quotes.value = newQuotes;
    }

    function reset() {
        campaigns.value = {};
        campaignName.value = "";
        campaignStatus.value = CAMPAIGN_STATUS.PENDING;
        quotes.value = [];
    }

    function updateDistributionDate(quoteId, newDate) {
        if (!campaigns.value[quoteId]) {
            campaigns.value[quoteId] = {};
        }
        campaigns.value[quoteId].distributionDate = getStartOfDay(newDate);
    }

    function updateJobName(quoteId, newJobName) {
        if (!campaigns.value[quoteId]) {
            campaigns.value[quoteId] = {};
        }
        campaigns.value[quoteId].jobName = newJobName;
    }

    return {
        campaigns,
        campaignName,
        campaignStatus,
        quotes,
        reset,
        updateCampaignName,
        updateCampaignStatus,
        updateDistributionDate,
        updateJobName,
        updateQuotes,
    };
});
