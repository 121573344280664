<script setup>
import { onMounted, ref } from "vue";
import { searchQuotes } from "../../services/apis/search";
import { useSearchStore } from "../../stores/search";
import UtilityButton from "../buttons/UtilityButton.vue";
import ProgressSpin from "../utils/ProgressSpin.vue";
import ErrorCard from "./ErrorCard.vue";

const searchStore = useSearchStore();
const searchEl = ref(null);
const searchTerm = ref("");
const searchTags = ref([]);
const isSearching = ref(false);

onMounted(() => {
    searchEl.value.focus();
});

const doSearch = () => {
    searchQuotes(searchTags.value);
};

const addTag = () => {
    if (searchTerm.value.trim() !== "") {
        searchTags.value.push(searchTerm.value.trim());
        doSearch();
        searchTerm.value = "";
    }
};

const removeTag = (tag) => {
    const indexToRemove = searchTags.value.findIndex((t) => t === tag);

    if (indexToRemove >= 0) {
        searchTags.value.splice(indexToRemove, 1);
        doSearch();
    }
};
</script>

<template>
    <div class="mb-4">
        <div class="flex flex-col sm:flex-row gap-4">
            <input
                ref="searchEl"
                v-model="searchTerm"
                class="p-2 rounded border border-gray-300 dark:bg-dark-grey hover:cursor-pointer focus:border-peppermint-tint-2 focus:outline-none w-full"
                placeholder="Enter quote reference or tags"
                @keydown="
                    (e) => {
                        if ('Enter' === e.key) {
                            addTag();
                        }
                    }
                "
            />
            <ProgressSpin v-if="isSearching"></ProgressSpin>
            <UtilityButton
                v-else
                label="Search"
                tooltip="Search for quotes"
                class="w-1/2 md:w-1/6 self-center"
                :onClick="addTag"
            ></UtilityButton>
        </div>
        <div class="flex flex-wrap mt-2">
            <div
                v-for="(tag, index) in searchTags"
                :key="`search-tag-${index}`"
                class="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-dark-grey bg-peppermint border border-peppermint"
            >
                <div
                    class="text-xs font-normal leading-none max-w-full flex-initial uppercase"
                >
                    {{ tag }}
                </div>
                <div class="flex flex-auto flex-row-reverse">
                    <div @click="() => removeTag(tag)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-x cursor-pointer hover:text-peppermint-tint-7 rounded-full w-4 h-4 ml-1"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <ErrorCard v-if="searchStore.error" class="text-center"
            ><p>{{ searchStore.error }}</p></ErrorCard
        >
    </div>
</template>
