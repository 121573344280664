import {
    GoogleAuthProvider,
    getAuth,
    onAuthStateChanged,
    signInWithRedirect,
    signOut,
} from "firebase/auth";
import {
    collection,
    doc,
    documentId,
    getDoc,
    getDocs,
    initializeFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { reactive } from "vue";
import { AUTH_UNKNOWN, CUSTOM_OAUTH_PARAMETERS } from "../../const/auth";
import { SIGN_IN_PATH } from "../../const/route";
import { getCampaign } from "../apis/campaign";
import { getQuotesAmount } from "../apis/quote";
import { getCampaignIds } from "../utils/campaign";
import { hasNewQuoteChanges } from "../utils/quote";
import { reportError, setTelemetryUser } from "../utils/telemetry";
import { showToast, toastStatus } from "../utils/toast";
import firebaseApp from "./init";

const auth = getAuth(firebaseApp);
const authState = reactive({
    auth,
    authenticated: AUTH_UNKNOWN,
    loading: true,
});

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsub = onAuthStateChanged(
            auth,
            (user) => {
                authState.loading = authState.loading
                    ? !authState.loading
                    : authState.loading;

                if (user) {
                    setTelemetryUser({
                        email: user.email,
                        id: user.uid,
                        name: user.displayName,
                    });
                }

                unsub();
                resolve(user);
            },
            reject
        );
    });
};

const signIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters(CUSTOM_OAUTH_PARAMETERS);

    try {
        await signInWithRedirect(auth, provider);
    } catch (error) {
        reportError(error);
        throw error;
    }
};

const isUserSignedIn = async () => {
    try {
        return (await getCurrentUser()) !== null;
    } catch (error) {
        reportError(error);
        return false;
    }
};

const userSignOut = () => {
    signOut(auth)
        .then(() => {
            authState.authenticated = AUTH_UNKNOWN;
            setTelemetryUser(null);
            window.location.assign(`/${SIGN_IN_PATH}`);
        })
        .catch((e) => reportError(e));
};

export const useAuth = () => {
    return {
        authState,
        getCurrentUser,
        signIn,
        isUserSignedIn,
        userSignOut,
    };
};

const db = initializeFirestore(firebaseApp, {
    experimentalForceLongPolling: true,
});

const quoteCollRef = collection(db, "quotes");
const getQuoteDocRef = (quoteId) => doc(db, "quotes", quoteId);

const loadAllQuotes = async () => {
    const snapshot = await getDocs(
        query(quoteCollRef, orderBy("quoteMeta.updatedOn", "desc"), limit(50))
    );

    return snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
    });
};

const searchQuotesByTags = async (searchTags) => {
    const snapshot = await getDocs(
        query(
            quoteCollRef,
            where("quoteMeta.tags", "array-contains-any", searchTags),
            orderBy("quoteMeta.updatedOn", "desc"),
            limit(100)
        )
    );

    return snapshot.docs.flatMap((doc) => {
        const quote = doc.data();

        if (searchTags.every((tag) => quote.quoteMeta.tags.includes(tag))) {
            return { id: doc.id, ...quote };
        }
        return [];
    });
};

const getQuotes = (quoteIds, quoteStore) => {
    const q = query(quoteCollRef, where(documentId(), "in", quoteIds));
    const unsub = onSnapshot(
        q,
        (querySnapshot) => {
            const quotes = [];
            let hasNewChanges = false;

            querySnapshot.forEach((doc) => {
                const newQuote = { id: doc.id, ...doc.data() };
                quotes.push(newQuote);

                const existingQuote = quoteStore.getQuote(doc.id);
                hasNewChanges =
                    existingQuote &&
                    hasNewQuoteChanges(existingQuote, newQuote);
            });

            if (quotes.length > 0) {
                // Reject if quotes have no customer attached
                if (
                    quotes.some(
                        (q) =>
                            !q.quoteMeta.customerCmsCode ||
                            q.quoteMeta.customerCmsCode.trim() === ""
                    )
                ) {
                    quoteStore.setError(
                        "Quotes must have a customer attached."
                    );
                    quoteStore.setLoading(false);
                } else if (
                    // Reject if quotes contain different customer
                    quotes.some(
                        (q) =>
                            q.quoteMeta.customerCmsCode.trim() !==
                            quotes[0].quoteMeta.customerCmsCode
                    )
                ) {
                    quoteStore.setError(
                        "Quotes must have the same customer attached."
                    );
                    quoteStore.setLoading(false);
                } else {
                    const campaignIds = getCampaignIds(quotes);

                    if (campaignIds.length === 1) {
                        getCampaign(campaignIds[0]);
                    } else if (campaignIds.length > 1) {
                        quoteStore.setError(
                            "Quotes must have the same campaign attached."
                        );
                        quoteStore.setLoading(false);
                        return;
                    }

                    // Get amounts from API
                    quoteStore.setQuotes(quotes);
                    getQuotesAmount(quotes);
                }

                if (hasNewChanges) {
                    showToast(
                        "There is a new update to the quote.",
                        toastStatus.INFO
                    );
                }
            }
        },
        (error) => {
            quoteStore.setError(error);
            reportError(error);
            quoteStore.setLoading(false);
        }
    );

    return unsub;
};

const updateQuote = (quoteId, changeSet) => {
    return updateDoc(getQuoteDocRef(quoteId), changeSet);
};

const isQuoteExist = async (quoteId) => {
    const doc = await getDoc(getQuoteDocRef(quoteId));

    return doc.exists();
};

export const useDb = () => {
    return {
        loadAllQuotes,
        searchQuotesByTags,
        getQuotes,
        updateQuote,
        isQuoteExist,
    };
};
