import { defineStore } from "pinia";
import { ref } from "vue";

export const useSearchStore = defineStore("search", () => {
    const quotes = ref([]);
    const loadingSearch = ref(true);
    const error = ref(undefined);

    function setQuotes(newQuotes) {
        quotes.value = newQuotes;
    }

    function setLoading(loading) {
        loadingSearch.value = loading;
    }

    function setError(e) {
        error.value = e;
    }

    return {
        error,
        loadingSearch,
        quotes,
        setError,
        setQuotes,
        setLoading,
    };
});
