export const openExternalLink = (url) => {
    window.open(url, "_blank");
};

export const redirectToCXView = (quoteId) => {
    openExternalLink(
        import.meta.env.VITE_CX_APP_URL + "#/load-quote/" + quoteId
    );
};

export const redirectToClientView = (quoteId) => {
    openExternalLink(import.meta.env.VITE_CLIENT_APP_URL + "?" + quoteId);
};

export const redirectToLibertyCampaign = (campaignId) => {
    openExternalLink(import.meta.env.VITE_LIBERTY_CAMPAIGN_URL + campaignId);
};

export const redirectToLibertyJob = (jobId) => {
    openExternalLink(import.meta.env.VITE_LIBERTY_JOB_URL + jobId);
};
