<script setup>
import { onMounted } from "vue";
import SectionHeader from "../components/texts/SectionHeader.vue";
import BaseView from "./BaseView.vue";
import SearchView from "./SearchView.vue";
import { useCampaignStore } from "../stores/campaign";
import { useSearchStore } from "../stores/search";

const campaignStore = useCampaignStore();
const searchStore = useSearchStore();

onMounted(() => {
    // Reset stores
    campaignStore.reset();
    searchStore.setQuotes([]);
});
</script>

<template>
    <BaseView>
        <div class="text-center my-16 w-3/4 xl:w-1/2 self-center">
            <SectionHeader
                label="No quote selected."
                :show-divider="false"
                :center="true"
            />
            <SearchView class="mt-8" />
        </div>
    </BaseView>
</template>
