<script setup>
import { DEFAULT_LODGEMENT, LODGEMENT_SITE_OPTIONS } from "../../const/quote";
import { formatCurrency } from "../../services/utils/formatter";
import SectionHeader from "../texts/SectionHeader.vue";

const props = defineProps({
    quote: { type: Object, required: true },
});
</script>

<template>
    <div>
        <SectionHeader label="Lodgement" class="mb-2" />
        <div class="overflow-x-auto">
            <table class="table table-auto" aria-describedby="Lodgement table">
                <thead>
                    <tr>
                        <th id="lodgement-site" rowspan="2">Site</th>
                        <th
                            id="lodgement-copies"
                            colspan="2"
                            class="text-center"
                        >
                            Copies
                        </th>
                        <th
                            id="lodgement-drops"
                            colspan="2"
                            class="text-center"
                        >
                            Drops
                        </th>
                    </tr>
                    <tr>
                        <th id="lodgement-copies-volume" class="text-center">
                            Volume
                        </th>
                        <th id="lodgement-copies-fee" class="text-center">
                            Fee
                        </th>
                        <th id="lodgement-drops-volume" class="text-center">
                            #
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="[site, lodgement] of Object.entries(
                            props.quote.quoteDetails.lodgement ??
                                DEFAULT_LODGEMENT
                        )"
                        :key="`lodgement-${site}`"
                    >
                        <td>{{ site }}</td>
                        <td class="text-center">
                            {{
                                lodgement[LODGEMENT_SITE_OPTIONS.COPIES][
                                    "volume"
                                ]
                            }}
                        </td>
                        <td class="text-center">
                            {{
                                formatCurrency(
                                    lodgement[LODGEMENT_SITE_OPTIONS.COPIES][
                                        "fee"
                                    ]
                                )
                            }}
                        </td>
                        <td class="text-center">
                            {{
                                lodgement[LODGEMENT_SITE_OPTIONS.DROPS][
                                    "volume"
                                ]
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
