import { defineStore } from "pinia";
import { ref } from "vue";

export const useCustomerStore = defineStore("customer", () => {
    const customerDetails = ref(null);
    const loadingCustomer = ref(false);

    function setCustomer(details) {
        customerDetails.value = details;
    }

    function setLoading(loading) {
        loadingCustomer.value = loading;
    }

    return { customerDetails, loadingCustomer, setCustomer, setLoading };
});
