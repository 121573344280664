export const HTTP = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
};

const ociGateway = import.meta.env.VITE_OCI_GATEWAY_URL;

export const ENDPOINT = {
    GET_ACCOUNT_CODE: (code) =>
        `${ociGateway}/customer/by-account-code/${code}`,
    CREATE_CAMPAIGN: () => `${ociGateway}/campaign/`,
    CREATE_QUOTE: (campaignId) => `${ociGateway}/campaign/${campaignId}/quotes`,
    CREATE_STANDALONE_JOB: () => `${ociGateway}/jobs/create`,
    GET_CAMPAIGN: (campaignId) => `${ociGateway}/campaign/${campaignId}`,
    GET_QUOTE_TOTAL: (q) => `${ociGateway}/calculator/total?${q}`,
    GET_ADMIN_FEE: (q) => `${ociGateway}/rates/admin?${q}`,
    GET_FARMER_QUOTE: (q) => `${ociGateway}/rates/farmer-premium?${q}`,
    GET_FREIGHT_QUOTE: (q) => `${ociGateway}/calculator/freight?${q}`,
    GET_NETWORK_QUOTE: (q) => `${ociGateway}/rates/network-management?${q}`,
    GET_VOLUME_QUOTE: (q) => `${ociGateway}/calculator/volume?${q}`,
    UPDATE_CAMPAIGN: (campaignId) => `${ociGateway}/campaign/${campaignId}`,
    UPDATE_QUOTE: (campaignId, quoteId) =>
        `${ociGateway}/campaign/${campaignId}/quotes/${quoteId}`,
    DELETE_QUOTE: (campaignId, quoteId) =>
        `${ociGateway}/campaign/${campaignId}/quotes/${quoteId}`,
};

export const HTTP_CODES = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    SERVER_ERROR: 500,
};
