import { useSearchStore } from "../../stores/search";
import { useDb } from "../firebase";
import { reportError } from "../utils/telemetry";

export const searchQuotes = async (searchTags) => {
    const db = useDb();
    const searchStore = useSearchStore();

    try {
        searchStore.setLoading(true);

        if (!searchTags || searchTags.length === 0) {
            searchStore.setQuotes(await db.loadAllQuotes());
        } else {
            searchStore.setQuotes(await db.searchQuotesByTags(searchTags));
        }
    } catch (error) {
        searchStore.setError(error);
        reportError(error);
    } finally {
        searchStore.setLoading(false);
    }
};
