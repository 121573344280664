<script setup>
import BillingRow from "./BillingRow.vue";
import {
    quotesSubtotal,
    quoteTaxAmount,
    quotesTotal,
} from "../../services/utils/calc";
import { useQuoteStore } from "../../stores/quote";
import { TAX_RATE } from "../../const/quote";

const quoteStore = useQuoteStore();
</script>

<template>
    <div class="mx-auto md:px-4">
        <div class="flex flex-col">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full sm:px-6">
                    <div class="overflow-x-auto">
                        <table
                            class="min-w-full table-auto border-separate border-spacing-y-2"
                            aria-describedby="Summary of quote costs"
                        >
                            <th class="p-0"></th>
                            <tbody>
                                <BillingRow
                                    title="Subtotal"
                                    :amount="quotesSubtotal(quoteStore.quotes)"
                                    force-display
                                />
                                <BillingRow
                                    :title="`GST (${TAX_RATE * 100}%)`"
                                    retain-title-case
                                    :amount="
                                        quoteTaxAmount(
                                            quotesSubtotal(quoteStore.quotes)
                                        )
                                    "
                                    force-display
                                />
                                <BillingRow
                                    title="Grand Total"
                                    bold-title
                                    bold-amount
                                    :amount="quotesTotal(quoteStore.quotes)"
                                    force-display
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
