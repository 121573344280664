<script setup>
import { onMounted, onUnmounted, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import UtilityButton from "../components/buttons/UtilityButton.vue";
import ErrorCard from "../components/cards/ErrorCard.vue";
import SearchQuoteCard from "../components/cards/SearchQuoteCard.vue";
import PlusIcon from "../components/icons/PlusIcon.vue";
import FullscreenModal from "../components/layout/FullscreenModal.vue";
import LoadingBackdrop from "../components/utils/LoadingBackdrop.vue";
import { QUOTE_LIMIT } from "../const/quote";
import { BASE_PATH, QUOTES_PATH } from "../const/route";
import { searchQuotes } from "../services/apis/search";
import { isCampaignLocked } from "../services/utils/campaign";
import { dateToYmd } from "../services/utils/date";
import { showToast } from "../services/utils/toast";
import { useCampaignStore } from "../stores/campaign";
import { useSearchStore } from "../stores/search";

const router = useRouter();
const route = useRoute();
const campaignStore = useCampaignStore();
const searchStore = useSearchStore();
const state = reactive({
    showModal: false,
    error: undefined,
    isLoading: true,
    quotes: [],
    selected: route.query.ids ? [...route.query.ids.split(",")] : [],
});

onMounted(async () => {
    window.addEventListener("keydown", closeModalOnEsc);

    searchStore.$subscribe((mut, storeState) => {
        state.error = storeState.error;
        state.isLoading = storeState.loadingSearch;
        state.quotes = storeState.quotes;
    });
});

onUnmounted(() => {
    window.removeEventListener("keydown", closeModalOnEsc);
    searchStore.setQuotes([]);

    searchStore.$dispose();
});

watch(
    () => state.showModal,
    (show) => {
        if (show && searchStore.quotes.length === 0) {
            searchQuotes();
        }
        if (!show) {
            searchStore.setQuotes([]);
        }
    }
);

const closeModalOnEsc = (ev) => {
    if (ev.key === "Escape") {
        state.showModal = false;
        searchStore.setQuotes([]);
    }
};

const reloadQueries = () => {
    router
        .push({
            path: `${BASE_PATH}${QUOTES_PATH}`,
            query: {
                ids: state.selected.join(","),
            },
        })
        .then(() => {
            router.go();
            searchStore.setQuotes([]);
        });
};

const checkQueryLimit = () => {
    if (state.selected.length >= QUOTE_LIMIT) {
        showToast(`You may select at most ${QUOTE_LIMIT} quotes.`);
        return false;
    }
    return true;
};

const handleSelected = (quoteId, checked) => {
    // Handle table row click
    if (checked === undefined) {
        if (state.selected.includes(quoteId)) {
            removeSelected(quoteId);
        } else if (checkQueryLimit()) {
            addSelected(quoteId);
        }
    } else if (checked) {
        if (checkQueryLimit()) {
            addSelected(quoteId);
        }
    } else {
        removeSelected(quoteId);
    }
};

const addSelected = (quoteId) => {
    state.selected.push(quoteId);
};

const removeSelected = (quoteId) => {
    const indexToRemove = state.selected.indexOf(quoteId);

    if (indexToRemove < 0) return;
    state.selected.splice(indexToRemove, 1);
};
</script>

<template>
    <div v-if="!isCampaignLocked(campaignStore.campaignStatus)" class="mt-2.5">
        <UtilityButton
            label=""
            tooltip="Search quotes"
            inverse
            noBorder
            :onClick="() => (state.showModal = true)"
        >
            <PlusIcon class="h-8 w-8" />
        </UtilityButton>

        <FullscreenModal
            v-if="state.showModal"
            title="Manage Quotes"
            :onClose="() => (state.showModal = false)"
        >
            <div>
                <SearchQuoteCard />
                <!-- Modal body -->
                <div class="overflow-y-auto search-table">
                    <LoadingBackdrop v-if="state.isLoading" />
                    <ErrorCard v-else-if="state.error"
                        ><p>{{ state.error }}</p>
                    </ErrorCard>
                    <table
                        v-else
                        class="overflow-scroll w-full"
                        aria-describedby="Quote search list"
                    >
                        <thead class="sticky top-0 z-10 h-10">
                            <tr class="bg-dark-grey text-white">
                                <th></th>
                                <th>Name</th>
                                <th>Campaign</th>
                                <th>Created on</th>
                                <th>Updated on</th>
                                <th>Owner</th>
                            </tr>
                        </thead>
                        <tbody class="h-72 overflow-y-auto">
                            <tr
                                v-for="quote in state.quotes"
                                :key="`search-${quote.quoteMeta.id}`"
                                class="break-words hover:bg-peppermint-tint-7 hover:text-dark-grey hover:cursor-pointer"
                                @click="
                                    handleSelected(
                                        quote.quoteMeta.id,
                                        $event.target.checked
                                    )
                                "
                            >
                                <td class="align-top pl-1 pr-2">
                                    <input
                                        type="checkbox"
                                        class="w-4 h-4 rounded cursor-pointer bg-gray-100 border-gray-400 dark:bg-gray-300 focus:border-peppermint focus:outline-none checked:text-peppermint"
                                        :checked="
                                            state.selected.includes(
                                                quote.quoteMeta.id
                                            )
                                        "
                                        @change="
                                            handleSelected(
                                                quote.quoteMeta.id,
                                                $event.target.checked
                                            )
                                        "
                                    />
                                </td>
                                <td class="w-5/12">
                                    <p class="font-bold">
                                        {{ quote.quoteMeta.name }}
                                    </p>
                                    <span
                                        v-if="quote.quoteMeta.details"
                                        class="pl-2 text-xs"
                                        >{{ quote.quoteMeta.details }}</span
                                    ><span
                                        v-for="(tag, tagIndex) in quote
                                            .quoteMeta.tags"
                                        :key="`${quote.quoteMeta.name}-tag-${tagIndex}`"
                                        ><span
                                            class="pl-1 text-xs uppercase inline-block"
                                            >[{{ tag }}]</span
                                        ></span
                                    >
                                </td>
                                <td class="text-center">
                                    {{
                                        quote.quoteMeta.campaignStartDate
                                            ? dateToYmd(
                                                  new Date(
                                                      quote.quoteMeta.campaignStartDate
                                                  )
                                              )
                                            : "&mdash;"
                                    }}
                                </td>
                                <td>
                                    {{
                                        dateToYmd(
                                            new Date(quote.quoteMeta.createdOn)
                                        )
                                    }}
                                </td>
                                <td>
                                    {{
                                        dateToYmd(
                                            new Date(quote.quoteMeta.updatedOn)
                                        )
                                    }}
                                </td>
                                <td>{{ quote.quoteMeta.createdBy }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Modal footer -->
                <div
                    class="flex justify-end my-4 py-4 border-t border-gray-200 dark:border-gray-600"
                >
                    <UtilityButton label="Confirm" :on-click="reloadQueries" />
                </div>
            </div>
        </FullscreenModal>
    </div>
</template>

<style scoped>
.search-table {
    max-height: 60vh;
}
</style>
