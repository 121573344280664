<script setup>
import { PRINT_SPECS } from "../../const/quote";
import { getWeightBracket } from "../../services/utils/calc";
import SectionHeader from "../texts/SectionHeader.vue";
import PillBadge from "../utils/PillBadge.vue";

const props = defineProps({
    quote: { type: Object, required: true },
});

const printingSpecs = () =>
    Object.keys(PRINT_SPECS)
        .filter((key) => key in props.quote.printingSpecs)
        .reduce((subset, key) => {
            subset[PRINT_SPECS[key]] = props.quote.printingSpecs[key];

            return subset;
        }, {});

const weightBracket = () =>
    getWeightBracket(props.quote.quoteDetails.customWeightBracket);
</script>

<template>
    <div v-if="props.quote.quoteDetails.printingLine">
        <SectionHeader label="Print Specifications" class="my-2" />
        <div>
            <PillBadge
                v-for="(spec, label) in printingSpecs()"
                :key="label"
                :label="spec"
            />
            <PillBadge
                v-if="weightBracket()"
                :label="`Custom weight @ [ ${weightBracket()} ]`"
            />
            <PillBadge
                v-if="props.quote.quoteDetails.extraCopies > 0"
                :label="`Extra [ ${props.quote.quoteDetails.extraCopies} ] copies`"
            />
        </div>
    </div>
</template>
