<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { DEFAULT_LOCALE } from "../../const/config";
import { JOB_NAME_CHAR_LIMIT } from "../../const/quote";
import { getQuoteAmount } from "../../services/apis/quote";
import { isCampaignLocked } from "../../services/utils/campaign";
import { dateToYmd, timestampToDate } from "../../services/utils/date";
import { redirectToLibertyJob } from "../../services/utils/navigation";
import { hasJobNumber } from "../../services/utils/quote";
import { useCampaignStore } from "../../stores/campaign";
import { useQuoteStore } from "../../stores/quote";
import { useJobStore } from "../../stores/job";

const props = defineProps({
    quote: { type: Object, required: true },
});

const campaignStore = useCampaignStore();
const jobStore = useJobStore();
const quoteStore = useQuoteStore();

const initialDistributionDate = () =>
    props.quote.quoteMeta.campaignStartDate
        ? dateToYmd(timestampToDate(props.quote.quoteMeta.campaignStartDate))
        : dateToYmd(new Date());
const initialJobName = () =>
    props.quote.quoteMeta.jobName && props.quote.quoteMeta.jobName !== ""
        ? props.quote.quoteMeta.jobName
        : props.quote.quoteMeta.jobInformation &&
            props.quote.quoteMeta.jobInformation !== ""
          ? props.quote.quoteMeta.jobInformation
          : props.quote.quoteMeta.name ?? "";

const distributionDate = ref(initialDistributionDate());
const jobName = ref(initialJobName());
const setAsCampaignName = ref(campaignStore.campaignName === jobName.value);

onMounted(() => {
    campaignStore.$subscribe((_mut, storeState) => {
        if (storeState.campaignName) {
            setAsCampaignName.value =
                campaignStore.campaignName === jobName.value;
        }
    });
});

onUnmounted(() => {
    campaignStore.$dispose();
});

watch(
    distributionDate,
    (newDate, _) => {
        campaignStore.updateDistributionDate(props.quote.id, newDate);
        jobStore.updateDistributionDate(newDate);
        getQuoteAmount(props.quote);
    },
    { immediate: true }
);
watch(
    jobName,
    (newJobName, _) => {
        campaignStore.updateJobName(props.quote.id, newJobName);
        jobStore.updateJobName(newJobName);
    },
    { immediate: true }
);
watch(
    setAsCampaignName,
    (checked, _) => {
        if (checked) {
            campaignStore.updateCampaignName(jobName.value);
        }
    },
    { immediate: true }
);
</script>

<template>
    <div>
        <div
            v-if="quoteStore.hasMultipleQuotes && hasJobNumber(props.quote)"
            class="flex"
        >
            <p>
                Contract number
                <a
                    class="cursor-pointer text-lg"
                    @click="
                        () =>
                            redirectToLibertyJob(
                                props.quote.quoteMeta.jobNumber
                            )
                    "
                    >{{ props.quote.quoteMeta.jobContractNumber }}</a
                >
            </p>
        </div>
        <div class="flex flex-col md:flex-row">
            <div class="p-2 w-full">
                <p class="mb-1 italic">Job name</p>
                <p
                    v-if="
                        (quoteStore.hasMultipleQuotes &&
                            isCampaignLocked(campaignStore.campaignStatus)) ||
                        (quoteStore.hasSingleQuote &&
                            hasJobNumber(quoteStore.quotes[0]))
                    "
                >
                    {{ props.quote.quoteMeta.jobName }}
                </p>
                <div v-else>
                    <input
                        type="text"
                        class="w-full bg-gray-50 border-2 border-gray-300 dark:bg-dark-grey focus:border-peppermint-tint-2 focus:outline-none rounded p-2 hover:cursor-pointer"
                        v-model="jobName"
                        :maxlength="JOB_NAME_CHAR_LIMIT"
                    />
                    <p
                        class="mt-1 text-xs text-end"
                        :class="{
                            'text-red dark:text-red-tint-6':
                                jobName.length > JOB_NAME_CHAR_LIMIT,
                        }"
                    >
                        {{ jobName.length }} / {{ JOB_NAME_CHAR_LIMIT }}
                    </p>
                </div>

                <div
                    v-if="
                        quoteStore.hasMultipleQuotes &&
                        !isCampaignLocked(campaignStore.campaignStatus)
                    "
                    class="flex mt-1"
                >
                    <input
                        :id="`campaign-name-checkbox-${props.quote.id}`"
                        type="checkbox"
                        v-model="setAsCampaignName"
                        class="mt-1 w-4 h-4 rounded cursor-pointer bg-gray-50 border-gray-100 dark:bg-gray-400 focus:border-peppermint focus:outline-none checked:text-peppermint"
                    />
                    <label
                        :for="`campaign-name-checkbox-${props.quote.id}`"
                        class="ml-2"
                        >Set as campaign name</label
                    >
                </div>
            </div>
            <div
                class="p-2 w-full lg:w-6/12"
                :class="
                    (quoteStore.hasMultipleQuotes &&
                        !isCampaignLocked(campaignStore.campaignStatus)) ||
                    (quoteStore.hasSingleQuote &&
                        !hasJobNumber(quoteStore.quotes[0]) &&
                        new Date(distributionDate).getTime() <
                            new Date().getTime())
                        ? 'past-date'
                        : ''
                "
            >
                <p class="mb-1 italic">Indicative distribution date</p>
                <p
                    v-if="
                        (quoteStore.hasMultipleQuotes &&
                            isCampaignLocked(campaignStore.campaignStatus)) ||
                        (quoteStore.hasSingleQuote &&
                            hasJobNumber(quoteStore.quotes[0]))
                    "
                    class="font-medium"
                >
                    {{
                        new Date(distributionDate).toLocaleDateString(
                            DEFAULT_LOCALE,
                            {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            }
                        )
                    }}
                </p>
                <input
                    v-else
                    type="date"
                    class="p-2 rounded border-2 border-gray-300 dark:bg-dark-grey hover:cursor-pointer focus:border-peppermint-tint-2 focus:outline-none"
                    v-model="distributionDate"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
.past-date {
    border-color: #f1a8a8;
    border-radius: 0.5em;
    border-width: 2px;
}
</style>
