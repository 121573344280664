<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { CAMPAIGN_NAME_CHAR_LIMIT } from "../../const/quote";
import { isCampaignLocked } from "../../services/utils/campaign";
import { useCampaignStore } from "../../stores/campaign";
import SectionHeader from "../texts/SectionHeader.vue";

const props = defineProps({
    quote: { type: Object, required: true },
});

const campaignStore = useCampaignStore();

const campaignName = ref(campaignStore.campaignName);

onMounted(() => {
    campaignStore.$subscribe((_mut, storeState) => {
        if (storeState.campaignName) {
            campaignName.value = storeState.campaignName;
        }
    });
});

onUnmounted(() => {
    campaignStore.$dispose();
});

watch(
    campaignName,
    (newName, _) => {
        campaignStore.updateCampaignName(newName);
    },
    { immediate: true }
);
</script>

<template>
    <div v-if="props.quote" class="align-center">
        <SectionHeader label="Campaign Details" />
        <div class="flex">
            <div class="p-2 w-full">
                <p class="mb-1 italic">Campaign name</p>
                <p v-if="isCampaignLocked(campaignStore.campaignStatus)">
                    {{ props.quote.quoteMeta.campaignName }}
                </p>
                <div v-else>
                    <input
                        type="text"
                        class="w-full bg-gray-50 border-2 border-gray-300 dark:bg-dark-grey focus:border-peppermint-tint-2 focus:outline-none rounded p-2 hover:cursor-pointer"
                        v-model="campaignName"
                        :maxlength="CAMPAIGN_NAME_CHAR_LIMIT"
                    />
                    <p
                        class="mt-1 text-xs text-end"
                        :class="{
                            'text-red dark:text-red-tint-6':
                                campaignName.length > CAMPAIGN_NAME_CHAR_LIMIT,
                        }"
                    >
                        {{ campaignName.length }} /
                        {{ CAMPAIGN_NAME_CHAR_LIMIT }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
