export const getStartOfDay = (date) => {
    const startOfDay = new Date(date.valueOf());

    startOfDay.setHours(0, 0, 0, 0);
    return startOfDay;
};

export const getStartOfNextWeek = () => {
    const now = new Date();
    const nextWeek = new Date(now.valueOf());

    // Start of week is Monday
    nextWeek.setDate(now.getDate() + ((7 - now.getDay() + 1) % 7 || 7));

    return getStartOfDay(nextWeek);
};

export const isDistributionDateValid = (distributionDate) => {
    const dt = getStartOfDay(distributionDate);

    return dt.getTime() >= getStartOfNextWeek().getTime();
};

export const timestampToDate = (ts) => {
    if (Object.hasOwn(ts, "seconds")) {
        return new Date(ts.seconds * 1000);
    }
    return new Date(ts);
};

export const dateToYmd = (date) => {
    return [
        date.getFullYear(),
        ("0" + (date.getMonth() + 1)).slice(-2),
        ("0" + date.getDate()).slice(-2),
    ].join("-");
};

export const apiDate = (date) => {
    let dateFormatted = "";
    if (date) {
        const dt = new Date(date);

        // alter the date format to dd-MMM-YYYY to conform with API
        const month = new Intl.DateTimeFormat("en-US", {
            month: "short",
        }).format(dt);
        dateFormatted = `${dt.getDate()}-${month}-${dt.getFullYear()}`;
    }
    return dateFormatted;
};
