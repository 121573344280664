<script setup>
import { DEFAULT_ADMIN_FEE, QUOTE_METRICS } from "../../const/quote";
import { numberFormat } from "../../services/utils/formatter";
import { getSelectedDistributions } from "../../services/utils/quote";
import BillingRow from "./BillingRow.vue";
import FreightBilling from "./FreightBilling.vue";
import VolumeBilling from "./VolumeBilling.vue";

const props = defineProps({
    quote: { type: Object, required: true },
});

const getDetailLabel = (key) => {
    if ("targetingCost" === key) {
        if (props.quote.quoteDetails.targeted) {
            return "Demographic targeting fee";
        }
        return "Geographic targeting fee";
    }
    return QUOTE_METRICS[key].label;
};

const getDetailAmount = (key) => {
    if (
        !props.quote.quoteDetails.hasFreight &&
        ["freightCost", "vfrCost"].includes(key)
    ) {
        return 0;
    }

    if ("printCost" === key && !props.quote.quoteDetails.printingLine) {
        return 0;
    }

    if ("adminFee" === key) {
        return props.quote.quoteDetails[key] || DEFAULT_ADMIN_FEE;
    }

    if ("minAmtAdjustment" === key) {
        return props.quote.quoteDetails.minAmtAdjustment || 0;
    }

    return props.quote.quoteDetails[key];
};

const getHelpText = (key) => {
    if (QUOTE_METRICS[key].helpText) {
        return QUOTE_METRICS[key].helpText(
            props.quote.quoteDetails.minFee || 0
        );
    }
    return undefined;
};

const highlightTitle = (key) => {
    if ("targetingCost" === key) {
        return props.quote.quoteDetails.targeted;
    }
    return false;
};

const getFreightSurchargeRate = () => {
    const { freightCost, vfrCost } = props.quote.quoteDetails;
    return numberFormat((Number(vfrCost) / Number(freightCost)) * 100);
};
</script>

<template>
    <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full sm:px-6">
                <div class="overflow-x-auto">
                    <VolumeBilling
                        :distributions="getSelectedDistributions(props.quote)"
                        group-title="Volume cost"
                        :group-sum="Number(props.quote.quoteDetails.volumeCost)"
                        distribution-type="volume"
                        description="Breakdown of volume costs"
                    />
                    <FreightBilling
                        v-if="props.quote.quoteDetails.hasFreight ?? true"
                        :distributions="getSelectedDistributions(props.quote)"
                        group-title="Freight cost"
                        :group-sum="
                            Number(props.quote.quoteDetails.freightCost)
                        "
                        freight-type="amount"
                        description="Breakdown of freight costs"
                    />
                    <FreightBilling
                        v-if="
                            (props.quote.quoteDetails.hasFreight ?? true) &&
                            Number(props.quote.quoteDetails.vfrCost ?? 0) > 0
                        "
                        :distributions="getSelectedDistributions(props.quote)"
                        group-title="Freight surcharge"
                        :group-subtitle="`[ ${getFreightSurchargeRate()}% of total freight ]`"
                        :group-sum="
                            Number(props.quote.quoteDetails.vfrCost ?? 0)
                        "
                        freight-type="vfr"
                        description="Breakdown of freight surcharges"
                    />
                    <table
                        class="min-w-full table-auto"
                        aria-describedby="Breakdown of quote costs"
                    >
                        <th></th>
                        <tbody>
                            <BillingRow
                                v-for="key in Object.keys(QUOTE_METRICS)"
                                :key="key"
                                :title="getDetailLabel(key)"
                                :amount="Number(getDetailAmount(key))"
                                :help-text="getHelpText(key)"
                                :highlight-title="highlightTitle(key)"
                                :bold-title="highlightTitle(key)"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
