import { ENDPOINT, HTTP } from "../../const/http";
import { NETWORK } from "../../const/quote";
import { useCampaignStore } from "../../stores/campaign";
import { useQuoteStore } from "../../stores/quote";
import { reportError } from "../utils/telemetry";
import { getWeightBracket } from "../utils/calc";
import { apiDate } from "../utils/date";
import { constructOptions, useApi } from "./common";

export const freightQuote = async (quote, network, volume) => {
    if (volume === 0) return;
    let networkCode;

    // translate network to Liberty naming convention
    if (
        [
            NETWORK.POSTIES,
            NETWORK.RURAL,
            NETWORK.POBOXES,
            NETWORK.COUNTERS,
        ].includes(network)
    ) {
        networkCode = "P";
    } else if (NETWORK.URBAN === network) {
        networkCode = "S";
    } else {
        console.log(`Unknown freight API network: ${network}`);
        return;
    }

    const { data, fetchApi } = useApi({
        optionsHandler: constructOptions,
    });
    const store = useQuoteStore();
    const campaignStore = useCampaignStore();

    const { customWeightBracket, weight } = quote.quoteDetails;
    const { pageCount } = quote.printingSpecs;

    // respect custom weight bracket choice to override weight/bracket calculations
    const weightBracket =
        customWeightBracket ?? getWeightBracket(weight * pageCount);

    const customerCmsCode = quote.quoteMeta.customerCmsCode;
    const campaignStartDate =
        campaignStore.campaigns[quote.id]?.distributionDate;
    const campaignStartDateFormatted = apiDate(campaignStartDate);

    const params = {
        network: networkCode,
        qty: volume,
        ...(customerCmsCode && { cms_acct_code: customerCmsCode }),
        ...(campaignStartDate && { start_date: campaignStartDateFormatted }),
        ...(weightBracket && { weight_range: weightBracket }),
    };

    try {
        await fetchApi({
            endPoint: ENDPOINT.GET_FREIGHT_QUOTE(new URLSearchParams(params)),
            method: HTTP.GET,
        });

        if (data.value) {
            const { freightcalc } = data.value;
            const freightCost = Number(freightcalc.amount ?? 0);
            const vfr = Number(freightcalc.vfr ?? 0);

            store.setNetworkFreightCost(quote.id, network, freightCost);
            store.setNetworkVfrCost(quote.id, network, vfr);
            return {
                amount: freightCost,
                vfr,
            };
        }
    } catch (error) {
        store.setError(error);
        reportError(error);
    }
};
