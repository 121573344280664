import { isEqual } from "lodash-es";
import { NETWORK, TAG_REACHHOME } from "../../const/quote";
import { hasCampaign } from "./campaign";

export const hasJobNumber = (quote) => {
    if (!quote) return false;
    return (
        quote.quoteMeta.jobNumber !== undefined &&
        quote.quoteMeta.jobNumber !== null &&
        quote.quoteMeta.jobNumber !== ""
    );
};

export const isQuoteLocked = (quote) => {
    if (!quote) return false;
    return (
        quote.quoteDetails.locked || hasCampaign(quote) || hasJobNumber(quote)
    );
};

export const hasDistributionData = (quote) => {
    if (!quote?.quoteDetails.distribution) {
        return false;
    }
    if (!quote.quoteDetails.distribution["COUNTERS"]) {
        return false;
    }

    return Object.values(quote.quoteDetails.distribution).some(
        (d) => d.freight.amount !== undefined
    );
};

export const hasCustomerDetails = (customerDetails) => {
    return customerDetails?.id;
};

export const isReachhomeQuote = (quote) => {
    return quote.quoteMeta.tags?.includes(TAG_REACHHOME.toLowerCase());
};

const trackedMeta = [
    "campaignStartDate",
    "customerCmsCode",
    "campaignId",
    "name",
];

const trackedSettings = ["targetingSummary"];

const trackedTerritoryFields = [
    "location",
    "networkSelections",
    "rounds",
    "volumeSelections",
    "volumeMatrix",
];

const trackedFields = ["networkVisibility", "quoteDetails", "targetingFilters"];

const hasNewTerritoryChanges = (oldTerritory, newTerritory) => {
    for (const field of trackedTerritoryFields) {
        if (!isEqual(oldTerritory[field], newTerritory[field])) {
            return true;
        }
    }
    return false;
};

const hasNewTerritoriesChanges = (oldQuote, newQuote) => {
    if (oldQuote.territories.length !== newQuote.territories.length) {
        return true;
    }
    for (let i = 0; i < oldQuote.territories.length; i++) {
        if (
            hasNewTerritoryChanges(
                oldQuote.territories[i],
                newQuote.territories[i]
            )
        ) {
            return true;
        }
    }
    return false;
};

export const hasNewQuoteChanges = (oldQuote, newQuote) => {
    for (const field of trackedFields) {
        if (!isEqual(oldQuote[field], newQuote[field])) {
            return true;
        }
    }
    for (const field of trackedMeta) {
        if (!isEqual(oldQuote.quoteMeta[field], newQuote.quoteMeta[field])) {
            return true;
        }
    }
    for (const field of trackedSettings) {
        if (!isEqual(oldQuote.settings[field], newQuote.settings[field])) {
            return true;
        }
    }

    if (newQuote.quoteDetails.printingLine) {
        if (!isEqual(oldQuote.printingSpecs, newQuote.printingSpecs)) {
            return true;
        }
    }

    return hasNewTerritoriesChanges(oldQuote, newQuote);
};

export const getSelectedDistributions = (quote) => {
    const result = {};
    const networkVisibility = quote.networkVisibility;
    const distributions = quote.quoteDetails.distribution;

    Object.keys(networkVisibility).forEach((network) => {
        if (networkVisibility[network]) {
            result[network] = distributions[network];

            if (network === NETWORK.POBOXES) {
                result[NETWORK.COUNTERS] = distributions[NETWORK.COUNTERS];
            }
        }
    });
    return result;
};

export const getQuotedNetworks = (subtotalMatrix) => {
    return Object.keys(subtotalMatrix).filter((channel) => {
        if (NETWORK.COUNTERS === channel) {
            return subtotalMatrix[NETWORK.POBOXES] > 0;
        }
        return subtotalMatrix[channel] > 0;
    });
};
