<script setup>
import SectionHeader from "../texts/SectionHeader.vue";
import { useAuth } from "../../services/firebase";

const { authState } = useAuth();
const user = authState.auth.currentUser;
</script>

<template>
    <div class="align-center">
        <SectionHeader label="Account Coordinator" />
        <p class="font-medium p-4">{{ user.email }}</p>
    </div>
</template>
