<script setup>
import { TARGETING_VARIABLES } from "../../const/quote";
import SectionSubtitle from "../texts/SectionSubtitle.vue";
import PillBadge from "../utils/PillBadge.vue";

const props = defineProps({
    territory: { type: Object, required: true },
    settings: { type: Object, required: false },
    targetingFilters: { type: Object, required: true },
});

const getTargetingGroups = () => {
    return (
        props.targetingFilters.find((t) => t.territoryId === props.territory.id)
            ?.groups || []
    );
};

const showFraction = (group) => {
    if (TARGETING_VARIABLES[group.variablesGroup].type === "Fraction") {
        return "%";
    }
    return "";
};
</script>

<template>
    <div
        v-if="
            props.settings &&
            props.settings.targetingSummary &&
            getTargetingGroups().length > 0
        "
    >
        <SectionSubtitle label="Targeting filters" class="text-xs" />
        <div>
            <PillBadge
                v-for="(group, index) in getTargetingGroups()"
                :key="`group-${index}`"
                label=""
            >
                <div class="flex flex-wrap">
                    <span class="font-medium pr-1">{{
                        TARGETING_VARIABLES[group.variablesGroup].name
                    }}</span>
                    <span
                        v-for="variable in group.variables"
                        :key="variable"
                        class="pr-1"
                        >/ {{ variable }}</span
                    >
                    <span
                        v-for="value in group.values"
                        :key="value"
                        class="pr-1"
                        >/ {{ value }}</span
                    >
                    <span v-if="group.minThreshold" class="pr-1"
                        >/ Min: {{ group.minThreshold
                        }}{{ showFraction(group) }}</span
                    >
                    <span v-if="group.maxThreshold" class="pr-1"
                        >/ Max: {{ group.maxThreshold
                        }}{{ showFraction(group) }}</span
                    >
                </div>
            </PillBadge>
        </div>
    </div>
</template>
