import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useQuoteStore = defineStore("quote", () => {
    const quotes = ref([]);
    const loadingQuote = ref(true);
    const error = ref(undefined);

    const hasSingleQuote = computed(() => quotes.value.length === 1);
    const hasMultipleQuotes = computed(() => quotes.value.length > 1);

    function setQuotes(newQuotes) {
        quotes.value = newQuotes;
    }

    function setQuoteDetailsValue(quoteId, key, val) {
        const index = quotes.value.findIndex((q) => quoteId === q.id);

        if (index < 0) return;
        quotes.value[index].quoteDetails[key] = val;
    }

    function setNetworkFreightCost(quoteId, channel, val) {
        const index = quotes.value.findIndex((q) => quoteId === q.id);

        if (index < 0) return;
        if (
            quotes.value[index].quoteDetails.distribution[channel].freight
                .amount === undefined
        ) {
            quotes.value[index].quoteDetails.distribution[channel].freight = {};
        }
        quotes.value[index].quoteDetails.distribution[channel].freight.amount =
            val;
    }

    function setNetworkVfrCost(quoteId, channel, val) {
        const index = quotes.value.findIndex((q) => quoteId === q.id);

        if (index < 0) return;
        if (
            quotes.value[index].quoteDetails.distribution[channel].freight
                .amount === undefined
        ) {
            quotes.value[index].quoteDetails.distribution[channel].freight = {};
        }
        quotes.value[index].quoteDetails.distribution[channel].freight.vfr =
            val;
    }

    function setNetworkVolumeCost(quoteId, channel, val) {
        const index = quotes.value.findIndex((q) => quoteId === q.id);

        if (index < 0) return;
        quotes.value[index].quoteDetails.distribution[channel].volume = val;
    }

    function setLoading(loading) {
        loadingQuote.value = loading;
    }

    function setError(e) {
        error.value = e;
    }

    function getQuote(quoteId) {
        return quotes.value.find((q) => q.id === quoteId);
    }

    return {
        error,
        hasSingleQuote,
        hasMultipleQuotes,
        quotes,
        loadingQuote,
        setError,
        setQuotes,
        setQuoteDetailsValue,
        setNetworkFreightCost,
        setNetworkVfrCost,
        setNetworkVolumeCost,
        setLoading,
        getQuote,
    };
});
