<script setup>
import SectionHeader from "../texts/SectionHeader.vue";
import { redirectToCXView } from "../../services/utils/navigation";
import ErrorCard from "../cards/ErrorCard.vue";
import { useCustomerStore } from "../../stores/customer";
import CustomerDetailsCard from "../customer/CustomerDetailsCard.vue";
import { useQuoteStore } from "../../stores/quote";
import { hasCustomerDetails } from "../../services/utils/quote";
import ProgressSpin from "../utils/ProgressSpin.vue";

const customerStore = useCustomerStore();
const quoteStore = useQuoteStore();
</script>

<template>
    <div class="align-center">
        <SectionHeader label="Customer Details" />

        <ProgressSpin v-if="customerStore.loadingCustomer" class="my-8" />
        <div v-else>
            <ErrorCard
                v-if="
                    !customerStore.loadingCustomer &&
                    !quoteStore.quotes[0].quoteMeta.customerCmsCode
                "
            >
                <p>
                    The quote does not have an associated customer, please open
                    the quote in the
                    <a
                        class="cursor-pointer"
                        @click="
                            () =>
                                redirectToCXView(
                                    quoteStore.quotes[0].quoteMeta.id
                                )
                        "
                        >application</a
                    >
                    and add a valid customer CMS number and save the quote.
                </p>
            </ErrorCard>
            <ErrorCard
                v-else-if="!hasCustomerDetails(customerStore.customerDetails)"
            >
                <p>
                    The customer associated with the quote cannot be found by
                    its CMS number
                    {{ quoteStore.quotes[0].quoteMeta.customerCmsCode }}. Please
                    make sure this is the valid value and its Liberty record has
                    been properly configured.
                </p>
            </ErrorCard>
            <CustomerDetailsCard
                v-else
                :customer="customerStore.customerDetails"
            />
        </div>
    </div>
</template>
