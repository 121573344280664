import { NETWORK, DEFAULT_ADMIN_FEE, TAX_RATE } from "../../const/quote";

const weightBrackets = {
    25: "0 — 25",
    51: "26 — 50",
    100: "51 — 100",
    150: "101 — 150",
    200: "151 — 200",
    250: "201 — 250",
    300: "251 — 300",
    350: "301 — 350",
    400: "351 — 400",
    450: "401 — 450",
    500: "451 — 500",
    550: "501 — 550",
    600: "551 — 600",
};

export const getWeightBracket = (weight) => {
    return Object.keys(weightBrackets).find((item) => item >= weight);
};

export const adminFee = (quoteDetails) => {
    return quoteDetails.adminFee || DEFAULT_ADMIN_FEE;
};

export const freight = (freightDistribution) => {
    if (freightDistribution.amount !== undefined) {
        return freightDistribution.amount + (freightDistribution.vfr || 0);
    }
    return freightDistribution;
};

/**
 * Logic to calculate network management fee to provide respective quote line

 * @param {Number} rate Per 1000
 * @param {Number} volume 
 */
export const networkManagement = (rate, volume) => {
    return (rate * volume) / 1000;
};

/**
 * Logic to calculate targeting fee to provide respective quote line

 * @param {Number} rate Per 1000
 * @param {Number} volume 
 */
export const targetingFee = (rate, volume) => {
    return (rate * volume) / 1000;
};

export const printCost = (quoteDetails) => {
    if (quoteDetails.printingLine) {
        return quoteDetails.printCost;
    } else {
        return 0;
    }
};

export const layerSubtotal = (territory, layer) => {
    const volumeSelections = territory.volumeSelections[layer];
    const volumesMatrix = territory.volumesMatrix[layer];

    if (!volumeSelections || !volumesMatrix) {
        return 0;
    }

    return volumeSelections.reduce((acc, slice) => {
        if (volumesMatrix[slice]) {
            let subtotal = acc + volumesMatrix[slice];
            if (
                NETWORK.POBOXES === layer &&
                territory.volumesMatrix[NETWORK.COUNTERS] &&
                territory.volumesMatrix[NETWORK.COUNTERS][slice]
            ) {
                subtotal += territory.volumesMatrix[NETWORK.COUNTERS][slice];
            }
            return subtotal;
        }
        return acc;
    }, 0);
};

export const quoteSubtotal = (quote) => {
    return quote.quoteDetails.subtotal || 0;
};

export const quoteTaxAmount = (subtotal) => {
    return subtotal * TAX_RATE;
};

export const quotesTotal = (quotes) => {
    let sum = quotesSubtotal(quotes);

    sum += quoteTaxAmount(sum);

    return sum;
};

export const quotesSubtotal = (quotes) => {
    return quotes.reduce((sum, quote) => sum + quoteSubtotal(quote), 0);
};
