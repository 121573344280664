import { ENDPOINT, HTTP } from "../../const/http";
import { useCampaignStore } from "../../stores/campaign";
import { useQuoteStore } from "../../stores/quote";
import { reportError } from "../utils/telemetry";
import {
    getWeightBracket,
    networkManagement,
    targetingFee,
} from "../utils/calc";
import { apiDate } from "../utils/date";
import {
    getTargetedTerritoriesIds,
    territoriesSubtotal,
    territoriesTotal,
} from "../utils/territory";
import { constructOptions, useApi } from "./common";

const cache = {};
const defaultRates = {
    T1: 0.0,
    T2: 0.0,
    T3: 0.0,
};

export const networkManagementQuote = async (quote) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructOptions,
    });
    const store = useQuoteStore();
    const campaignStore = useCampaignStore();
    let rates = defaultRates;

    try {
        const customerCmsCode = quote.quoteMeta.customerCmsCode;
        const campaignStartDate =
            campaignStore.campaigns[quote.id]?.distributionDate;
        const campaignStartDateFormatted = apiDate(campaignStartDate);
        const cacheKey = `${customerCmsCode}-${campaignStartDateFormatted}`;

        if (cache[cacheKey]) {
            return cache[cacheKey];
        }

        const { customWeightBracket, weight } = quote.quoteDetails;
        const { pageCount } = quote.printingSpecs;

        // respect custom weight bracket choice to override weight/bracket calculations
        const weightBracket =
            customWeightBracket ?? getWeightBracket(weight * pageCount);

        const params = {
            ...(customerCmsCode && { cms_acct_code: customerCmsCode }),
            ...(weightBracket && { weight_range: weightBracket }),
            ...(campaignStartDate && {
                start_date: campaignStartDateFormatted,
            }),
        };

        await fetchApi({
            endPoint: ENDPOINT.GET_NETWORK_QUOTE(new URLSearchParams(params)),
            method: HTTP.GET,
        });

        if (data.value) {
            cache[cacheKey] = data.value.rates;
            rates = data.value.rates;
        }

        const { isTargeted, subtotalMatrix } = quote.quoteDetails;
        const networkManagementCost = networkManagement(
            rates["T1"] ?? 0,
            territoriesTotal(subtotalMatrix)
        );

        // All quotes will have basic targeting T2 applied
        let targetingCost = targetingFee(
            rates["T2"] ?? 0,
            territoriesTotal(subtotalMatrix)
        );
        const targetedMarix = territoriesSubtotal(
            getTargetedTerritoriesIds(quote)
        );

        // Use T3 for advanced targeting
        if (isTargeted || territoriesTotal(targetedMarix) > 0) {
            targetingCost = targetingFee(
                rates["T3"] ?? 0,
                territoriesTotal(subtotalMatrix)
            );
        }

        store.setQuoteDetailsValue(
            quote.id,
            "networkManagementCost",
            networkManagementCost
        );
        store.setQuoteDetailsValue(quote.id, "targetingCost", targetingCost);
    } catch (error) {
        store.setError(error);
        reportError(error);
    }
};
