<script setup>
import { formatCurrency, toTitleCase } from "../../services/utils/formatter";
import HelpIcon from "../icons/HelpIcon.vue";

const props = defineProps({
    title: { type: String, required: true },
    subtitle: { type: String },
    amount: { type: Number, required: true },
    helpText: { type: String },
    forceDisplay: { type: Boolean },
    retainTitleCase: { type: Boolean },
    boldTitle: { type: Boolean },
    boldAmount: { type: Boolean },
    indent: { type: Boolean },
    highlightTitle: { type: Boolean },
});
</script>

<template>
    <tr v-if="props.forceDisplay || props.amount > 0">
        <td
            class="px-2"
            :class="{
                'font-bold': props.boldTitle,
                'pl-8': props.indent,
                'text-blue dark:text-bluesteel': props.highlightTitle,
            }"
        >
            {{ retainTitleCase ? props.title : toTitleCase(props.title)
            }}<span v-if="props.subtitle" class="italic pl-2">{{
                props.subtitle
            }}</span>
            <div v-if="props.helpText" class="ml-1 tooltip">
                <HelpIcon />
                <span class="tooltip-text">{{ props.helpText }}</span>
            </div>
        </td>
        <td class="px-2 text-end" :class="{ 'font-bold': props.boldAmount }">
            {{ formatCurrency(props.amount) }}
        </td>
    </tr>
</template>

<style scoped>
.tooltip {
    display: inline-block;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 12rem;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}
</style>
