<script setup>
const props = defineProps({
    label: { type: [String, Number], required: true },
});
</script>

<template>
    <div class="flex items-center my-1">
        <!-- Icon slot -->
        <slot></slot>
        {{ props.label }}
    </div>
</template>
