<script setup>
import { NETWORK, LAYERS } from "../../const/quote";
import BillingRow from "./BillingRow.vue";

const props = defineProps({
    distributions: { type: Object, required: true },
    groupTitle: { type: String, required: true },
    groupSum: { type: Number, required: true },
    description: { type: String, required: false, default: "" },
});

const getAmount = (key) => {
    if (NETWORK.POBOXES === key) {
        return (
            Number(props.distributions[key].volume) +
            Number(props.distributions[NETWORK.COUNTERS]?.volume ?? 0)
        );
    }
    return Number(props.distributions[key].volume);
};
</script>

<template>
    <table
        class="min-w-full table-auto my-1"
        :aria-describedby="props.description"
    >
        <th></th>
        <tbody>
            <BillingRow
                :title="props.groupTitle"
                retain-title-case
                :amount="props.groupSum"
            />
            <template
                v-for="key in Object.keys(props.distributions)"
                :key="`${props.groupTitle} - ${key}`"
            >
                <BillingRow
                    v-if="LAYERS[key]"
                    :title="LAYERS[key]"
                    retain-title-case
                    indent
                    :amount="getAmount(key)"
                />
            </template>
        </tbody>
    </table>
</template>
