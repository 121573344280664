import { defineStore } from "pinia";
import { ref } from "vue";
import { getStartOfDay } from "../services/utils/date";
import { CAMPAIGN_STATUS, JOB_NAME_CHAR_LIMIT } from "../const/quote";

export const useJobStore = defineStore("job", () => {
    const jobName = ref("");
    const distributionDate = ref(new Date());
    const jobStatus = ref(CAMPAIGN_STATUS.PENDING);

    function updateJobName(newName) {
        jobName.value = newName.substring(0, JOB_NAME_CHAR_LIMIT);
    }

    function updateJobStatus(newStatus) {
        if (!CAMPAIGN_STATUS[newStatus]) {
            jobStatus.value = CAMPAIGN_STATUS.OTHER;
        }
        jobStatus.value = CAMPAIGN_STATUS[newStatus];
    }

    function updateDistributionDate(newDate) {
        distributionDate.value = getStartOfDay(newDate);
    }

    function $reset() {
        jobName.value = "";
        distributionDate.value = new Date();
        jobStatus.value = CAMPAIGN_STATUS.PENDING;
    }

    return {
        distributionDate,
        jobName,
        jobStatus,
        $reset,
        updateJobStatus,
        updateDistributionDate,
        updateJobName,
    };
});
