import { ENDPOINT, HTTP } from "../../const/http";
import { useCampaignStore } from "../../stores/campaign";
import { useQuoteStore } from "../../stores/quote";
import { reportError } from "../utils/telemetry";
import { getWeightBracket } from "../utils/calc";
import { apiDate } from "../utils/date";
import { constructOptions, useApi } from "./common";

const networksMap = {
    POSTIES: "A",
    URBAN: "B",
    POBOXES: "C",
    COUNTERS: "C",
    RURAL: "D",
};

export const volumeQuote = async (quote, network, volume) => {
    if (volume === 0) return;
    if (!networksMap[network]) return;

    const { data, fetchApi } = useApi({
        optionsHandler: constructOptions,
    });
    const store = useQuoteStore();
    const campaignStore = useCampaignStore();

    const customerCmsCode = quote.quoteMeta.customerCmsCode;
    const campaignStartDate =
        campaignStore.campaigns[quote.id]?.distributionDate;
    const campaignStartDateFormatted = apiDate(campaignStartDate);
    const { customWeightBracket, weight, enclosed, oversized, mailerType } =
        quote.quoteDetails;
    const { pageCount } = quote.printingSpecs;
    const weightBracket =
        customWeightBracket ?? getWeightBracket(weight * pageCount);

    const params = {
        channel: networksMap[network],
        qty: volume,
        weight_range: weightBracket,
        enclosed_yn: enclosed ? "Y" : "N",
        oversize_yn: oversized ? "Y" : "N",
        dist_group: mailerType,
        ...(customerCmsCode && { cms_acct_code: customerCmsCode }),
        ...(campaignStartDate && { start_date: campaignStartDateFormatted }),
    };

    try {
        await fetchApi({
            endPoint: ENDPOINT.GET_VOLUME_QUOTE(new URLSearchParams(params)),
            method: HTTP.GET,
        });

        if (data.value) {
            const { volumecalc } = data.value;
            store.setNetworkVolumeCost(
                quote.id,
                network,
                Number(volumecalc.amount)
            );
            return { amount: Number(volumecalc.amount) };
        }
    } catch (error) {
        store.setError(error);
        reportError(error);
    }
};
