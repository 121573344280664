<script setup>
import { computed } from "vue";
import { getCampaignId, isCampaignLocked } from "../../services/utils/campaign";
import {
    hasCustomerDetails,
    hasDistributionData,
    hasJobNumber,
} from "../../services/utils/quote";
import { useCampaignStore } from "../../stores/campaign";
import { useCustomerStore } from "../../stores/customer";
import { useQuoteStore } from "../../stores/quote";
import ButtonBase from "./ButtonBase.vue";

const props = defineProps({
    onConfirm: { type: Function, required: true },
});

const campaignStore = useCampaignStore();
const customerStore = useCustomerStore();
const quoteStore = useQuoteStore();

const label = computed(() => {
    if (quoteStore.hasMultipleQuotes) {
        return getCampaignId(quoteStore.quotes)
            ? "Update Campaign"
            : "Book Campaign";
    } else {
        return "Book Job";
    }
});
</script>

<template>
    <ButtonBase
        class="peppermint-button focus-effect font-medium"
        :label="label"
        :disabled="
            !hasCustomerDetails(customerStore.customerDetails) ||
            quoteStore.quotes.some((q) => !hasDistributionData(q)) ||
            (quoteStore.hasMultipleQuotes &&
                isCampaignLocked(campaignStore.campaignStatus)) ||
            (quoteStore.hasSingleQuote && hasJobNumber(quoteStore.quotes[0]))
        "
        :on-click="props.onConfirm"
    />
</template>
