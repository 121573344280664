import {
    BASE_PATH,
    NOT_FOUND_PATH,
    QUOTES_PATH,
    SIGN_IN_PATH,
} from "../const/route";
import Home from "../views/HomeView.vue";
import NotFound from "../views/NotFoundView.vue";
import Quotes from "../views/QuotesView.vue";

export const routes = [
    {
        path: BASE_PATH,
        name: BASE_PATH,
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${QUOTES_PATH}`,
        name: QUOTES_PATH,
        component: Quotes,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}:pathMatch(.*)`,
        name: NOT_FOUND_PATH,
        component: NotFound,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${SIGN_IN_PATH}`,
        name: SIGN_IN_PATH,
        component: () => import("../views/SignIn.vue"),
    },
];
