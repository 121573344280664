<script setup>
import { NETWORK, FREIGHT_CHANNELS } from "../../const/quote";
import BillingRow from "./BillingRow.vue";

const props = defineProps({
    distributions: { type: Object, required: true },
    groupTitle: { type: String, required: true },
    groupSubtitle: { type: String },
    groupSum: { type: Number, required: true },
    description: { type: String, required: false, default: "" },
    freightType: { type: String, required: true },
});

const getAmount = (key) => {
    // Combine rural channels
    if (NETWORK.URBAN !== key) {
        const distributions = { ...props.distributions };
        delete distributions[NETWORK.URBAN];

        return Object.keys(distributions).reduce((pre, cur) => {
            return (
                distributions[cur]?.freight[props.freightType] ??
                distributions[cur]?.freight ??
                0
            );
        }, 0);
    }

    return (
        props.distributions[key]?.freight[props.freightType] ??
        props.distributions[key]?.freight ??
        0
    );
};
</script>

<template>
    <table
        class="min-w-full table-auto my-1"
        :aria-describedby="props.description"
    >
        <th></th>
        <tbody>
            <BillingRow
                :title="props.groupTitle"
                :subtitle="props.groupSubtitle"
                retain-title-case
                :amount="props.groupSum"
            />
            <template
                v-for="key in FREIGHT_CHANNELS"
                :key="`${props.groupTitle} - ${key}`"
            >
                <BillingRow
                    v-if="getAmount(key) > 0"
                    :title="FREIGHT_CHANNELS[key]"
                    retain-title-case
                    indent
                    :amount="getAmount(key)"
                />
            </template>
        </tbody>
    </table>
</template>
