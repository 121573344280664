<script setup>
import MapMarker from "../icons/MapMarker.vue";
import SmartPhone from "../icons/SmartPhone.vue";
import UserIcon from "../icons/UserIcon.vue";
import CustomerDetailRow from "./CustomerDetailRow.vue";
import { formatPostcode } from "../../services/utils/formatter";

const props = defineProps({
    customer: { type: Object, required: true },
});
</script>

<template>
    <div>
        <CustomerDetailRow
            class="text-xl font-bold mt-4"
            :label="props.customer.name"
        ></CustomerDetailRow>
        <CustomerDetailRow
            class=""
            :label="`Account CMS No: ${props.customer.cmsAcctCode}`"
        ></CustomerDetailRow>
        <CustomerDetailRow :label="props.customer.contact"
            ><UserIcon class="text-peppermint mr-2"
        /></CustomerDetailRow>
        <CustomerDetailRow
            v-if="props.customer.phone"
            :label="props.customer.phone"
            ><SmartPhone class="text-peppermint mr-2"
        /></CustomerDetailRow>
        <CustomerDetailRow :label="props.customer.address1"
            ><MapMarker class="text-peppermint mr-2"
        /></CustomerDetailRow>
        <CustomerDetailRow
            v-if="props.customer.address2"
            :label="props.customer.address2"
            ><span class="mr-6"></span
        ></CustomerDetailRow>
        <CustomerDetailRow
            v-if="props.customer.address3"
            :label="props.customer.address3"
            ><span class="mr-6"></span
        ></CustomerDetailRow>
        <CustomerDetailRow :label="formatPostcode(props.customer.postcode)"
            ><span class="mr-6"></span
        ></CustomerDetailRow>
    </div>
</template>
