<script setup>
import { formatCurrency } from "../../services/utils/formatter";
import CartIcon from "../icons/CartIcon.vue";
// import FreightIcon from "../icons/RocketIcon.vue";
import MailerIcon from "../icons/MailerIcon.vue";

const props = defineProps({
    channel: { type: String, required: true },
    volume: { type: Number, required: false },
    volumeCost: { type: Number, required: false },
    freightCost: { type: Number, required: false },
});
</script>

<template>
    <div class="flex flex-col justify-center lg:p-4 p-2 border rounded-lg">
        <div class="text-center text-sm text-gray-500 dark:text-gray-400 mb-1">
            {{ props.channel }}
        </div>
        <div v-if="props.volume !== undefined" class="mb-2">
            <MailerIcon />
            <span class="align-middle ml-2">{{ props.volume }}</span>
        </div>
        <div v-if="props.volumeCost !== undefined" class="mb-2">
            <CartIcon />
            <span class="align-middle ml-2">{{
                formatCurrency(props.volumeCost)
            }}</span>
        </div>
        <!-- <div v-if="props.freightCost !== undefined" class="mb-2">
            <FreightIcon />
            <span class="align-middle ml-2">{{
                formatCurrency(props.freightCost)
            }}</span>
        </div> -->
    </div>
</template>
