<script setup>
const props = defineProps({
    label: { type: String, required: true },
    color: { type: String, required: false, default: "peppermint" },
});
</script>

<template>
    <span
        class="bg-peppermint text-sm inline-block m-2 px-2.5 py-0.5 rounded-xl button-text"
        :class="`bg-${props.color}`"
        >{{ props.label }}<slot></slot
    ></span>
</template>
