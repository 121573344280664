import { ENDPOINT, HTTP } from "../../const/http";
import { useCampaignStore } from "../../stores/campaign";
import { useQuoteStore } from "../../stores/quote";
import { reportError } from "../utils/telemetry";
import { apiDate } from "../utils/date";
import { financialNumberFormat } from "../utils/formatter";
import { getQuotedNetworks } from "../utils/quote";
import { getAdminFee } from "./adminFee";
import { constructOptions, useApi } from "./common";
import { getCustomerDetails } from "./customer";
import { getFarmerPremium } from "./farmerPremium";
import { freightQuote } from "./freight";
import { networkManagementQuote } from "./networkManagement";
import { volumeQuote } from "./volume";

export const getQuotesAmount = async (quotes) => {
    if (quotes && quotes.length > 0) {
        // Get amounts from API
        await Promise.all([
            ...quotes.map(async (quote) => getQuoteAmount(quote)),
            getCustomerDetails(quotes[0].quoteMeta.customerCmsCode),
        ]);
    }

    const store = useQuoteStore();
    store.setLoading(false);
};

export const getQuoteAmount = async (quote) => {
    const store = useQuoteStore();

    // Get amounts from API
    try {
        await getAdminFee(quote);
    } catch (error) {
        reportError(error);
    }

    try {
        await networkManagementQuote(quote);
    } catch (error) {
        reportError(error);
    }

    try {
        await getFarmerPremium(quote);
    } catch (error) {
        reportError(error);
    }

    const { subtotalMatrix } = quote.quoteDetails;
    const enabledChannels = getQuotedNetworks(subtotalMatrix);

    try {
        const volumeCost = await getVolumeCost(
            quote,
            enabledChannels,
            subtotalMatrix
        );
        store.setQuoteDetailsValue(quote.id, "volumeCost", volumeCost);
    } catch (error) {
        reportError(error);
    }

    try {
        const { freightCost, vfrCost } = await getFreightCost(
            quote,
            enabledChannels,
            subtotalMatrix
        );

        store.setQuoteDetailsValue(quote.id, "freightCost", freightCost);
        store.setQuoteDetailsValue(quote.id, "vfrCost", vfrCost);
    } catch (error) {
        reportError(error);
    }

    try {
        await getQuoteTotal(quote);
    } catch (error) {
        reportError(error);
    }
};

export const getQuoteTotal = async (quote) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructOptions,
    });
    const store = useQuoteStore();
    const campaignStore = useCampaignStore();

    try {
        const {
            volumeCost,
            freightCost,
            vfrCost,
            hasFreight,
            printCost,
            networkManagementCost,
            targetingCost,
            adminFee,
            dairyFarmerPremium,
            printingLine,
            extraCopiesFee,
        } = quote.quoteDetails;
        const customerCmsCode = quote.quoteMeta.customerCmsCode;
        const campaignStartDate =
            campaignStore.campaigns[quote.id]?.distributionDate;
        const campaignStartDateFormatted = apiDate(campaignStartDate);

        const params = {
            volumeCost: financialNumberFormat(volumeCost),
            freightCost: financialNumberFormat(
                hasFreight ? freightCost + (vfrCost || 0) : 0
            ),
            printCost: financialNumberFormat(printingLine ? printCost : 0),
            networkManagementCost: financialNumberFormat(networkManagementCost),
            targetingCost: financialNumberFormat(targetingCost),
            adminFee: financialNumberFormat(adminFee),
            dairyFarmerPremium: financialNumberFormat(dairyFarmerPremium),
            extraCopiesFee: financialNumberFormat(extraCopiesFee),
            ...(customerCmsCode && { cms_acct_code: customerCmsCode }),
            ...(campaignStartDate && {
                start_date: campaignStartDateFormatted,
            }),
        };
        await fetchApi({
            endPoint: ENDPOINT.GET_QUOTE_TOTAL(new URLSearchParams(params)),
            method: HTTP.GET,
        });

        store.setQuoteDetailsValue(quote.id, "subtotal", data.value.totalCost);
        store.setQuoteDetailsValue(
            quote.id,
            "minAmtAdjustment",
            data.value.minAmtAdjustment
        );
        store.setQuoteDetailsValue(quote.id, "minFee", data.value.minFee);
    } catch (error) {
        store.setError(error);
        reportError(error);
    }
};

const getVolumeCost = async (quote, enabledChannels, subtotalMatrix) => {
    const requests = enabledChannels.map((channel) =>
        volumeQuote(quote, channel, subtotalMatrix[channel] ?? 0)
    );
    let volumeCost = 0;

    await Promise.all(requests).then((data) => {
        let hasZero = false;
        const quotes = data.map((item) => item?.amount ?? 0);

        for (const [index, channel] of enabledChannels.entries()) {
            if (quotes[index] === 0 && (subtotalMatrix[channel] || 0) !== 0) {
                hasZero = true;
            }
        }

        // Fallback to POA if we get at least one POA in response (zeroed)
        if (hasZero) {
            volumeCost = 0;
        } else {
            volumeCost = quotes.reduce((acc, val) => val + acc, 0);
        }
    });
    return volumeCost;
};

const getFreightCost = async (quote, enabledChannels, subtotalMatrix) => {
    const requests = enabledChannels.map((channel) =>
        freightQuote(quote, channel, subtotalMatrix[channel] ?? 0)
    );
    let freightCost = 0;
    let vfrCost = 0;

    await Promise.all(requests).then((data) => {
        const quotes = data.map((item) => item ?? {});

        for (const [index, _] of enabledChannels.entries()) {
            const distribution = quotes[index] ?? {};

            freightCost += distribution.amount ?? 0;
            vfrCost += distribution.vfr ?? 0;
        }
    });
    return { freightCost, vfrCost };
};
