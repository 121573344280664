import * as Sentry from "@sentry/vue";

export const reportError = (error, context) => {
    Sentry.captureException(error, {
        extra: context,
    });
};

export const setTelemetryUser = (user) => {
    if (user) {
        Sentry.setUser({
            id: user.id,
            username: user.name,
            email: user.email,
        });
    } else {
        Sentry.setUser(null);
    }
};

export const tryParseError = (error) => {
    try {
        return JSON.parse(error);
    } catch (e) {
        return error;
    }
};
