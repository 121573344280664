<script setup>
import { LAYERS } from "../../const/quote";
import { layerSubtotal } from "../../services/utils/calc";
import ChannelSummaryCard from "../cards/ChannelSummaryCard.vue";
import TargetingSummary from "./TargetingSummary.vue";

const props = defineProps({
    quote: { type: Object, required: true },
    territory: { type: Object, required: true },
    index: { type: Number, required: true },
});

const isNetworkSelected = (layer) => {
    return props.territory.networkSelections.includes(layer);
};
</script>

<template>
    <div class="w-full border rounded-md dark:border-gray-500 shadow-md p-4">
        <h3 class="font-bold text-lg mb-2">
            {{
                props.territory.location?.formatted?.main_text ||
                `Territory ${index + 1}`
            }}
        </h3>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mx-auto">
            <ChannelSummaryCard
                v-for="layer in Object.entries(LAYERS)"
                :key="`${layer[0]}-layer-summary`"
                :class="[
                    isNetworkSelected(layer[0])
                        ? 'border border-peppermint rounded-xl'
                        : '',
                ]"
                :channel="layer[1]"
                :volume="
                    isNetworkSelected(layer[0])
                        ? layerSubtotal(props.territory, layer[0])
                        : 0
                "
            />
        </div>
        <TargetingSummary
            :territory="props.territory"
            :settings="props.quote.settings"
            :targeting-filters="props.quote.targetingFilters || []"
            class="mt-2"
        />
    </div>
</template>
