<script setup>
import { NETWORK, LAYERS } from "../../const/quote";
import SectionSubtitle from "../texts/SectionSubtitle.vue";
import ChannelSummaryCard from "../cards/ChannelSummaryCard.vue";
import { freight } from "../../services/utils/calc";

const props = defineProps({
    quote: { type: Object, required: true },
});

const getVolume = (layer) => {
    if (!props.quote.networkVisibility[layer]) {
        return 0;
    }
    if (NETWORK.POBOXES === layer) {
        return (
            props.quote.quoteDetails.subtotalMatrix[layer] +
            props.quote.quoteDetails.subtotalMatrix[NETWORK.COUNTERS]
        );
    }
    return props.quote.quoteDetails.subtotalMatrix[layer];
};

const getCost = (layer, type) => {
    if (!props.quote.networkVisibility[layer]) {
        return 0;
    }
    if (NETWORK.POBOXES === layer) {
        if (type === "freight") {
            return (
                freight(props.quote.quoteDetails.distribution[layer][type]) +
                freight(
                    props.quote.quoteDetails.distribution[NETWORK.COUNTERS]?.[
                        type
                    ] ?? 0
                )
            );
        }
        return (
            props.quote.quoteDetails.distribution[layer][type] +
            (props.quote.quoteDetails.distribution[NETWORK.COUNTERS]?.[type] ??
                0)
        );
    }
    if (type === "freight") {
        return freight(props.quote.quoteDetails.distribution[layer][type]);
    }
    return props.quote.quoteDetails.distribution[layer][type];
};
</script>

<template>
    <div>
        <SectionSubtitle label="Subtotal Overview" />
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mx-auto mt-2">
            <ChannelSummaryCard
                v-for="layer in Object.entries(LAYERS)"
                :key="`${layer[0]}-layer-subtotal`"
                :channel="layer[1]"
                :volume="getVolume(layer[0])"
                :volume-cost="
                    props.quote.quoteDetails.distribution &&
                    props.quote.quoteDetails.distribution[layer[0]]
                        ? getCost(layer[0], 'volume')
                        : undefined
                "
                :freight-cost="
                    props.quote.quoteDetails.distribution &&
                    props.quote.quoteDetails.distribution[layer[0]]
                        ? getCost(layer[0], 'freight')
                        : undefined
                "
            >
            </ChannelSummaryCard>
        </div>
    </div>
</template>
