import { ENDPOINT, HTTP } from "../../const/http";
import { useCustomerStore } from "../../stores/customer";
import { reportError } from "../utils/telemetry";
import { constructBearerAuthOptions, useApi } from "./common";

export const getCustomerDetails = async (cmsCode) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructBearerAuthOptions,
    });
    const store = useCustomerStore();

    try {
        store.setLoading(true);
        await fetchApi({
            endPoint: ENDPOINT.GET_ACCOUNT_CODE(cmsCode),
            method: HTTP.GET,
        });
        store.setCustomer(data.value);
    } catch (error) {
        store.setCustomer(null);
        reportError(error);
    } finally {
        store.setLoading(false);
    }
};
