import { ENDPOINT, HTTP } from "../../const/http";
import { useAuth, useDb } from "../firebase";
import { dateToYmd } from "../utils/date";
import {
    createCampaignPayload,
    createCampaignQuotePayload,
    createStandaloneJobPayload,
} from "../utils/campaign";
import { constructBearerAuthOptions, useApi } from "./common";
import { useCampaignStore } from "../../stores/campaign";

export const getCampaign = async (campaignId) => {
    if (!campaignId) return;

    const { data, fetchApi } = useApi({
        optionsHandler: constructBearerAuthOptions,
    });
    const store = useCampaignStore();

    await fetchApi({
        endPoint: ENDPOINT.GET_CAMPAIGN(campaignId),
        method: HTTP.GET,
    });

    if (data.value?.status) {
        store.updateCampaignName(data.value.name);
        store.updateCampaignStatus(data.value.status.toUpperCase());
        store.updateQuotes(data.value.quotes ?? []);
    }
};

export const createCampaign = async (quotes, campaigns, campaignName) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructBearerAuthOptions,
    });

    const { auth } = useAuth().authState;
    await fetchApi({
        endPoint: ENDPOINT.CREATE_CAMPAIGN(),
        method: HTTP.POST,
        payload: createCampaignPayload(
            campaignName,
            quotes[0].quoteMeta.customerCmsCode.toString(),
            auth.currentUser.email
        ),
    });

    if (!data.value?.id) return;

    const campaignId = data.value.id.toString();

    quotes.forEach(async (quote) => {
        const campaign = campaigns[quote.id];
        if (!campaign) {
            throw new Error(
                "Cannot find associated quote in job distribution."
            );
        }

        await createQuote(
            campaignId,
            quote,
            dateToYmd(campaign.distributionDate),
            campaignName,
            campaign.jobName
        );
    });
};

export const updateCampaign = async (
    campaignId,
    quotes,
    existingQuotes,
    campaigns,
    campaignName
) => {
    const { error, fetchApi } = useApi({
        optionsHandler: constructBearerAuthOptions,
    });

    const { auth } = useAuth().authState;
    const ops = [
        fetchApi({
            endPoint: ENDPOINT.UPDATE_CAMPAIGN(campaignId),
            method: HTTP.PUT,
            payload: createCampaignPayload(
                campaignName,
                quotes[0].quoteMeta.customerCmsCode.toString(),
                auth.currentUser.email
            ),
        }),
    ];

    quotes.forEach((q) => {
        if (existingQuotes.find((c) => c.reference === q.id)) {
            ops.push(
                updateCampaignQuote(
                    campaignId,
                    campaignName,
                    q,
                    dateToYmd(campaigns[q.id]?.distributionDate),
                    campaigns[q.id]?.jobName
                )
            );
        } else {
            ops.push(
                createQuote(
                    campaignId,
                    q,
                    dateToYmd(campaigns[q.id]?.distributionDate),
                    campaignName,
                    campaigns[q.id]?.jobName
                )
            );
        }
    });

    existingQuotes.forEach((c) => {
        if (quotes.find((q) => c.reference === q.id) === undefined) {
            ops.push(deleteQuote(campaignId, c.reference));
        }
    });

    await Promise.all(ops);

    if (error) {
        throw new Error(`${JSON.stringify(error)}`);
    }
};

export const createStandaloneJob = async (quote, distributionDate, jobName) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructBearerAuthOptions,
    });

    const { auth } = useAuth().authState;
    await fetchApi({
        endPoint: ENDPOINT.CREATE_STANDALONE_JOB(),
        method: HTTP.POST,
        payload: createStandaloneJobPayload(
            quote,
            dateToYmd(distributionDate),
            jobName,
            quote.quoteMeta.customerCmsCode.toString(),
            auth.currentUser.email
        ),
    });

    if (!data.value) return;

    const db = useDb();

    await db.updateQuote(quote.quoteMeta.id, {
        "quoteMeta.campaignStartDate": dateToYmd(distributionDate),
        "quoteMeta.jobName": jobName.trim(),
        "quoteMeta.jobNumber": data.value.id ?? "",
        "quoteMeta.jobContractNumber": data.value.contract_number ?? "",
        "quoteMeta.updatedBy": auth.currentUser.email,
        "quoteMeta.updatedOn": new Date().toISOString(),
    });
};

export const createQuote = async (
    campaignId,
    quote,
    distributionDate,
    campaignName,
    jobName
) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructBearerAuthOptions,
    });

    await fetchApi({
        endPoint: ENDPOINT.CREATE_QUOTE(campaignId),
        method: HTTP.POST,
        payload: createCampaignQuotePayload(quote, distributionDate, jobName),
    });
    if (!data.value) return;

    const { auth } = useAuth().authState;
    const db = useDb();
    const index = data.value.quotes.findIndex((q) => q.reference === quote.id);

    await db.updateQuote(quote.quoteMeta.id, {
        "quoteMeta.campaignStartDate": dateToYmd(distributionDate),
        "quoteMeta.campaignId": campaignId,
        "quoteMeta.campaignName": campaignName.trim(),
        "quoteMeta.jobName": jobName.trim(),
        "quoteMeta.jobNumber":
            data.value.quotes[index < 0 ? 0 : index].id ?? "",
        "quoteMeta.jobContractNumber":
            data.value.quotes[index < 0 ? 0 : index].contract_number ?? "",
        "quoteMeta.updatedBy": auth.currentUser.email,
        "quoteMeta.updatedOn": new Date().toISOString(),
    });
};

export const updateCampaignQuote = async (
    campaignId,
    campaignName,
    quote,
    distributionDate,
    jobName
) => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructBearerAuthOptions,
    });

    await fetchApi({
        endPoint: ENDPOINT.UPDATE_QUOTE(campaignId, quote.quoteMeta.id),
        method: HTTP.PUT,
        payload: createCampaignQuotePayload(quote, distributionDate, jobName),
    });
    if (!data.value) return;

    const { auth } = useAuth().authState;
    const db = useDb();
    const index = data.value.quotes.findIndex((q) => q.reference === quote.id);

    await db.updateQuote(quote.quoteMeta.id, {
        "quoteMeta.campaignId": campaignId,
        "quoteMeta.campaignName": campaignName.trim(),
        "quoteMeta.campaignStartDate": dateToYmd(distributionDate),
        "quoteMeta.jobName": jobName.trim(),
        "quoteMeta.jobNumber":
            data.value.quotes[index < 0 ? 0 : index].id ?? "",
        "quoteMeta.jobContractNumber":
            data.value.quotes[index < 0 ? 0 : index].contract_number ?? "",
        "quoteMeta.updatedBy": auth.currentUser.email,
        "quoteMeta.updatedOn": new Date().toISOString(),
    });
};

export const deleteQuote = async (campaignId, quoteId) => {
    const { fetchApi } = useApi({
        optionsHandler: constructBearerAuthOptions,
    });

    await fetchApi({
        endPoint: ENDPOINT.DELETE_QUOTE(campaignId, quoteId),
        method: HTTP.DELETE,
    });

    // Remove campaign reference
    const { auth } = useAuth().authState;
    const db = useDb();
    await db.updateQuote(quoteId, {
        "quoteMeta.campaignId": "",
        "quoteMeta.campaignName": "",
        "quoteMeta.jobNumber": "",
        "quoteMeta.jobContractNumber": "",
        "quoteMeta.updatedBy": auth.currentUser.email,
        "quoteMeta.updatedOn": new Date().toISOString(),
    });
};
