import { formatCurrency } from "../services/utils/formatter";

export const TAX_RATE = 0.15;
export const DEFAULT_ADMIN_FEE = 0;
export const QUOTE_LIMIT = 10; // Firestore `in` operator limit

export const JOB_NAME_CHAR_LIMIT = 80; // Number of characters
export const CAMPAIGN_NAME_CHAR_LIMIT = 50; // Number of characters

export const CAMPAIGN_STATUS = {
    PENDING: "PENDING",
    CONFIRMED: "CONFIRMED",
    OTHER: "OTHER",
};

export const QUOTE_METRICS = {
    apartmentsPremium: {
        label: "Apartment fee",
    },
    dairyFarmerPremium: {
        label: "Dairy fee",
    },
    networkManagementCost: {
        label: "Network fee",
    },
    targetingCost: {
        label: "Targeting fee",
    },
    minAmtAdjustment: {
        label: "Minimum fee",
        helpText: (minFee) =>
            `There is a minimum charge of ${formatCurrency(minFee)}.`,
    },
    printCost: {
        label: "Print cost",
    },
    extraCopiesFee: {
        label: "Extra copies fee",
    },
    adminFee: {
        label: "Administration fee",
    },
};

export const PRINT_SPECS = {
    paperSize: "Paper size",
    paperType: "Paper type",
    pagination: "Pagination",
    gsm: "Gsm",
    colours: "Colours",
};

export const LODGEMENT_SITE = {
    AUCKLAND: "AUCKLAND",
    CHRISTCHURCH: "CHRISTCHURCH",
};

export const LODGEMENT_SITE_OPTIONS = {
    DROPS: "DROPS",
    COPIES: "COPIES",
};

export const DEFAULT_LODGEMENT = {
    [LODGEMENT_SITE.AUCKLAND]: {
        [LODGEMENT_SITE_OPTIONS.DROPS]: {
            volume: 0,
            fee: 0,
        },
        [LODGEMENT_SITE_OPTIONS.COPIES]: {
            volume: 0,
            fee: 0,
        },
    },
    [LODGEMENT_SITE.CHRISTCHURCH]: {
        [LODGEMENT_SITE_OPTIONS.DROPS]: {
            volume: 0,
            fee: 0,
        },
        [LODGEMENT_SITE_OPTIONS.COPIES]: {
            volume: 0,
            fee: 0,
        },
    },
};

export const NETWORK = {
    URBAN: "URBAN",
    POSTIES: "POSTIES",
    RURAL: "RURAL",
    POBOXES: "POBOXES",
    COUNTERS: "COUNTERS",
};

export const LAYERS = {
    URBAN: "REACH",
    POSTIES: "POSTIES",
    RURAL: "RURAL",
    POBOXES: "BOXES",
};

export const FREIGHT_CHANNELS = {
    URBAN: "URBAN",
    RURAL: "RURAL",
};

export const VOLUME_SLICES = {
    INCLUSIVE: "INCLUSIVE",
    EXCLUSIVE: "EXCLUSIVE",
    RESIDENTIAL: "RESIDENTIAL",
    BUSINESS: "BUSINESS",
    FARMER: "FARMER",
    DAIRY: "DAIRY",
    NEWSPAPER_INCLUSIVE: "NEWSPAPER_INCLUSIVE",
    NEWSPAPER_EXCLUSIVE: "NEWSPAPER_EXCLUSIVE",
    NEWSPAPER_ALL: "NEWSPAPER_ALL",
};

export const TARGETING_VARIABLES = {
    population: {
        name: "Population",
        title: "Add population target",
        options: ["Population", "Dwellings", "DPID"],
        type: "Number",
    },
    income: {
        name: "Income",
        title: "Add income target",
        options: ["ave_hh_income"],
        type: "Number",
    },
    age: {
        name: "Age",
        title: "Add age target",
        options: [
            "age_0_4_years",
            "age_5_9_years",
            "age_10_14_years",
            "age_15_19_years",
            "age_20_24_years",
            "age_25_29_years",
            "age_30_34_years",
            "age_35_39_years",
            "age_40_44_years",
            "age_45_49_years",
            "age_50_54_years",
            "age_55_59_years",
            "age_60_64_years",
            "age_65_",
            "median_age",
        ],
        type: "Fraction",
    },
    medianAge: {
        name: "Median Age",
        title: "Add median age target",
        options: ["median_age"],
        type: "Number",
    },
    gender: {
        name: "Gender",
        title: "Add gender target",
        options: ["male", "female"],
        type: "Fraction",
    },
    ethnicity: {
        name: "Ethnicity",
        title: "Add ethnicity target",
        options: [
            "european",
            "maori",
            "pacific_peoples",
            "asian",
            "middle_eastern_latin_american",
            "other_ethnicity",
        ],
        type: "Fraction",
    },
    profession: {
        name: "Profession",
        title: "Add profession target",
        options: [
            "managers",
            "professionals",
            "technicians_and_trades_workers",
            "community_and_personal_service",
            "clerical_and_administrative",
            "sales_workers",
            "machinery_operators_and_drivers",
            "labourers",
        ],
        type: "Fraction",
    },
    tenancy: {
        name: "Tenancy",
        title: "Add tenancy target",
        options: ["homeowners", "renters"],
        type: "Fraction",
    },
    employmentStatus: {
        name: "Employment ",
        title: "Add employment target",
        options: ["employed", "unemployed", "self_employed_"],
        type: "Fraction",
    },
    qualification: {
        name: "Qualification",
        title: "Add qualification target",
        options: [
            "no_qualification",
            "secondary_qualification",
            "tertiary_qualification",
        ],
        type: "Fraction",
    },
    familyComposition: {
        name: "Family Composition",
        title: "Add family composition target",
        options: [
            "couple_without_children",
            "couple_with_child_ren",
            "one_parent_with_child_ren",
        ],
        type: "Fraction",
    },
    student: {
        name: "Student",
        title: "Add student target",
        options: ["studying", "not_studying"],
        type: "Fraction",
    },
    marrige: {
        name: "Marriage",
        title: "Add marriage target",
        options: [
            "married_not_separated",
            "separated_divorced",
            "never_married_or_civil_union",
        ],
        type: "Fraction",
    },
    origin: {
        name: "Origin",
        title: "Add origin target",
        options: ["nz_born", "overseas_born"],
        type: "Fraction",
    },
    internetAccess: {
        name: "Internet Access",
        title: "Add access to internet target",
        options: ["no_access_to_telecommunication", "access_to_the_internet"],
        type: "Fraction",
    },
    smokingStatus: {
        name: "Smoking Status",
        title: "Add smoking status",
        options: ["regular_smoker", "ex_smoker", "never_smoked_regularly"],
        type: "Fraction",
    },
    deprivationIndex: {
        name: "Deprivation Index",
        title: "Add deprivation index target",
        options: ["average_deprivation_index"],
        type: "Number",
    },
    dominantMosaicGroup: {
        name: "Dominant Mosaic Group",
        title: "Add dominant mosaic group target",
        options: ["Dominant_Mosaic_Group"],
        valueOptions: [
            "a corporate ladder kings",
            "b reaping the rewards",
            "c tractors and trees",
            "d smartphone savvies",
            "e watching the pennies",
            "f generation next",
            "g crochet communities",
            "h bargains, benefits and babies",
            "i extended cultural living",
        ],
        type: "String",
    },
    mosaicGroup: {
        name: "Mosaic Group",
        title: "Add mosaic group target",
        options: [
            "a_corporate_ladder_kings",
            "b_reaping_the_rewards",
            "c_tractors_and_trees",
            "d_smartphone_savvies",
            "e_watching_the_pennies",
            "f_generation_next",
            "g_crochet_communities",
            "h_bargains__benefits_and_babies",
            "i_extended_cultural_living",
        ],
        type: "Fraction",
    },
    dominantMosaicType: {
        name: "Dominant Mosaic Type",
        title: "Add dominant mosaic type target",
        options: ["Dominant_Mosaic_Type"],
        valueOptions: [
            "a01 power professionals",
            "a02 wine and waves",
            "a03 double dollar suits",
            "a04 gold plated ride-ons",
            "b05 tea and biscuits",
            "b06 outdoor wanderers",
            "b07 backwater bowls",
            "c08 high speed harvesters",
            "c09 farming the wilderness",
            "c10 rustic retirees",
            "d11 working the network",
            "d12 hash tag look at me",
            "d14 touchscreens and takeaways",
            "d15 halftime hopes",
            "e16 carefree suburbia",
            "e17 creatures of habit",
            "e18 mortgages and microwaves",
            "e19 newspapers and knitting",
            "f20 turbo treadmills",
            "f21 win the better life",
            "f22 just add water",
            "g23 messaging motorheads",
            "g24 community greys",
            "g25 talkback and telly",
            "h26 fishing for a bargain",
            "h27 hiring the lifestyle",
            "h28 remote controlling",
            "h29 stately renters",
            "h30 kids and cars",
            "i31 window shopping strugglers",
            "i32 square eyes and empty pockets",
            "i33 extending the benefits",
            "i34 playdough and pizza",
        ],
        type: "String",
    },
    mosaicType: {
        name: "Mosaic Type",
        title: "Add mosaic type target",
        options: [
            "a01_power_professionals",
            "a02_wine_and_waves",
            "a03_double_dollar_suits",
            "a04_gold_plated_ride_ons",
            "b05_tea_and_biscuits",
            "b06_outdoor_wanderers",
            "b07_backwater_bowls",
            "c08_high_speed_harvesters",
            "c09_farming_the_wilderness",
            "c10_rustic_retirees",
            "d11_working_the_network",
            "d12_hash_tag_look_at_me",
            "d13_sharing_spaces",
            "d14_touchscreens_and_takeaways",
            "d15_halftime_hopes",
            "e16_carefree_suburbia",
            "e17_creatures_of_habit",
            "e18_mortgages_and_microwaves",
            "e19_newspapers_and_knitting",
            "f20_turbo_treadmills",
            "f21_win_the_better_life",
            "f22_just_add_water",
            "g23_messaging_motorheads",
            "g24_community_greys",
            "g25_talkback_and_telly",
            "h26_fishing_for_a_bargain",
            "h27_hiring_the_lifestyle",
            "h28_remote_controlling",
            "h29_stately_renters",
            "h30_kids_and_cars",
            "i31_window_shopping_strugglers",
            "i32_square_eyes_and_empty_pockets",
            "i33_extending_the_benefits",
            "i34_playdough_and_pizza",
        ],
        type: "Fraction",
    },
};

export const TAG_REACHHOME = "REACHHOME";
