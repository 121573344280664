<script setup>
import ErrorIcon from "../components/icons/ErrorIcon.vue";
import SectionHeader from "../components/texts/SectionHeader.vue";
import BaseView from "./BaseView.vue";

const props = defineProps({
    message: { type: String, required: true },
});
</script>

<template>
    <BaseView>
        <div class="text-center my-16 mx-8 h-full">
            <ErrorIcon class="large-icon" />
            <SectionHeader
                label="Oops! Something Went Wrong"
                class="my-8"
                :show-divider="false"
                :center="true"
            />
            <p class="my-8">{{ props.message }}</p>
            <router-link to="/" replace>Return to Home page</router-link>
        </div>
    </BaseView>
</template>
